import React, { useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';
import { z, emailAddressSchema } from '~/common/utils/validations';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Input, SvgIcon } from '~/common/components/ui-elements';
import { colorPrimary } from '~/common/utils';
import { postPassword } from '~/common/services/build-your-own-service';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useRecoilState } from 'recoil';
import {
  BuildYourOwnState,
  useRefreshAuthenticationDetails,
} from '../../store';
import { ZodIssueCode } from 'zod';
import { OverlayLoader } from '../..';

type Nullish = string | undefined | null;
interface PasswordInfo {
  email: Nullish;
  password: Nullish;
  newPassword: Nullish;
  newPasswordConfirm: Nullish;
}

const defaultPasswordInfo: PasswordInfo = {
  email: '',
  password: '',
  newPassword: '',
  newPasswordConfirm: '',
};

const PasswordSchema = z
  .object({
    email: z
      .string()
      .transform((val) => (typeof val === 'string' ? val.trim() : val))
      .nullish()
      .refine((value) => value && value.length > 0, {
        message: 'Email address is required',
      })
      .refine((value) => emailAddressSchema.safeParse(value).success),
    password: z
      .string()
      .transform((val) => (typeof val === 'string' ? val.trim() : val))
      .nullish()
      .refine((value) => value && value.length > 0, {
        message: 'Password is required',
      }),
    newPassword: z
      .string()
      .transform((val) => (typeof val === 'string' ? val.trim() : val))
      .nullish()
      .refine((value) => value && value.length > 0, {
        message: 'New password is required',
      }),
    newPasswordConfirm: z
      .string()
      .transform((val) => (typeof val === 'string' ? val.trim() : val))
      .nullish()
      .refine((value) => value && value.length > 0, {
        message: 'Confirm your new password',
      }),
  })
  .superRefine((data, ctx) => {
    if (data.newPassword && data.newPassword !== data.newPasswordConfirm) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['newPasswordConfirm'],
        message: 'Confirm your new password',
      });
    }
    if (
      data.password &&
      data.newPassword &&
      data.password === data.newPassword
    ) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['newPassword'],
        message: 'New password provided is equal to current password',
      });
    }
  });

type FormSchema = z.infer<typeof PasswordSchema>;

export const AccountPassword = React.memo(() => {
  const [byoState] = useRecoilState(BuildYourOwnState);
  const refreshAuthenticationDetails = useRefreshAuthenticationDetails();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordConfirm, setShowNewPasswordConfirm] = useState(false);
  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  const returnUrl = searchParams.returnUrl as string | undefined;
  const user = searchParams.user as string | undefined;
  const { control, handleSubmit, formState } = useForm<FormSchema>({
    resolver: zodResolver(PasswordSchema),
    defaultValues: { ...defaultPasswordInfo, email: user || '' },
    mode: 'all',
  });

  const handleFormSubmit = async (data: FormSchema) => {
    try {
      setErrorMessage('');
      setIsSubmitted(true);

      await postPassword(data).then(async (res) => {
        if (res.ok) {
          if (returnUrl) {
            window.location.href = returnUrl;
          } else {
            window.location.href = `/${byoState.baseUrlSegment}/profile`; // dashboard
          }
        } else {
          setErrorMessage('Invalid email or password');
          setIsSubmitted(false);
        }
      });
    } catch {
      setErrorMessage('Something went wrong');
      console.error('Something went wrong on login');
      setIsSubmitted(false);
    }
  };

  const onLoad = useCallback(async () => {
    await refreshAuthenticationDetails();
  }, [refreshAuthenticationDetails]);

  useEffect(() => {
    onLoad();
    window['pushDigitalData']({
      event: '_pageLoaded',
    });
  }, [onLoad]);

  return (
    <div className={styles.AccountPassword}>
      {isSubmitted && <OverlayLoader />}
      <div className={styles.Container}>
        <div className={styles.FormContainer}>
          <h2>Change Password</h2>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    autoComplete="off"
                    label="Email address"
                    variant="outlined"
                    className="col-span-12"
                    value={field.value || ''}
                    error={formState.errors?.email?.message}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    autoComplete="off"
                    type={showPassword ? 'text' : 'password'}
                    label="Current password"
                    variant="outlined"
                    className="col-span-12"
                    endAdornment={
                      <SvgIcon
                        style={{ cursor: 'pointer' }}
                        color={`#${colorPrimary}`}
                        type={showPassword ? 'eyeClosed' : 'eye'}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    }
                    value={field.value || ''}
                    error={formState.errors?.password?.message}
                  />
                )}
              />
              <Controller
                name="newPassword"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    autoComplete="off"
                    type={showNewPassword ? 'text' : 'password'}
                    label="New password"
                    variant="outlined"
                    className="col-span-12"
                    endAdornment={
                      <SvgIcon
                        style={{ cursor: 'pointer' }}
                        color={`#${colorPrimary}`}
                        type={showNewPassword ? 'eyeClosed' : 'eye'}
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    }
                    value={field.value || ''}
                    error={formState.errors?.newPassword?.message}
                  />
                )}
              />
              <Controller
                name="newPasswordConfirm"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    type={showNewPasswordConfirm ? 'text' : 'password'}
                    className="col-span-12"
                    label="Confirm new password"
                    variant="outlined"
                    endAdornment={
                      <SvgIcon
                        style={{ cursor: 'pointer' }}
                        color={`#${colorPrimary}`}
                        type={showNewPasswordConfirm ? 'eyeClosed' : 'eye'}
                        onClick={() =>
                          setShowNewPasswordConfirm(!showNewPasswordConfirm)
                        }
                      />
                    }
                    value={field.value || ''}
                    error={formState.errors.newPasswordConfirm?.message}
                  />
                )}
              />
            </div>
            {errorMessage && (
              <div className={styles.ErrorMessage}>{errorMessage}</div>
            )}
            <div className={styles.SubmitGroup}>
              <Button
                nativeType="submit"
                type="primary"
                buttonSize="medium"
                disabled={isSubmitted}
              >
                Change
              </Button>
              {!byoState.isAuthenticated && (
                <Button
                  type="text"
                  href={`/build-your-own/account/login${
                    returnUrl ? `?returnUrl=${returnUrl}` : ''
                  }`}
                  title="Proceed to login"
                  disabled={isSubmitted}
                  tabIndex={-1}
                >
                  Proceed to login
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});
