import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styles from './index.module.scss';
import cn from 'classnames';
import {
  BuildYourOwnState,
  useRefreshAuthenticationDetails,
} from '../../store';
import { OrderBanner } from '../order-banner';
import { OrderSummary } from '../order-summary';
import { useRecoilState } from 'recoil';
import { StickyChild, OverlayLoader } from '../..';
import {
  Button,
  Dropdown,
  Input,
  Loading,
} from '~/common/components/ui-elements';
import {
  getBuyerDetails,
  getSellerDetails,
  postDeposit,
} from '~/common/services/build-your-own-service';
import { EntityType, Option } from '~/common/models';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const monthOptions = [
  { label: 'January', value: '01' },
  { label: 'February', value: '02' },
  { label: 'March', value: '03' },
  { label: 'April', value: '04' },
  { label: 'May', value: '05' },
  { label: 'June', value: '06' },
  { label: 'July', value: '07' },
  { label: 'August', value: '08' },
  { label: 'September', value: '09' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];

const generateYearOptions = () => {
  const currentYear = new Date().getFullYear();
  const years: Option[] = [];
  for (let i = 0; i <= 19; i++) {
    const year = currentYear + i;
    const yearString = year.toString();
    const lastTwoDigits = yearString.slice(-2);
    years.push({ label: yearString, value: lastTwoDigits });
  }
  return years;
};

const yearOptions = generateYearOptions();

type Nullish = string | undefined | null;
interface PaymentInfo {
  orderReference: string;
  cardName: Nullish;
  cardNumber: Nullish;
  cardExpiryMonth: Nullish;
  cardExpiryYear: Nullish;
  cardCVN: Nullish;
  amount: number;
}

const defaultPaymentInfo: PaymentInfo = {
  orderReference: '',
  cardName: '',
  cardNumber: '',
  cardExpiryMonth: '',
  cardExpiryYear: '',
  cardCVN: '',
  amount: 1000,
};

const PaymentSchema = z.object({
  orderReference: z
    .string()
    .transform((val) => (typeof val === 'string' ? val.trim() : val))
    .nullish()
    .refine((value) => value && value.length > 0, {
      message: 'Order reference is required',
    }),
  cardName: z
    .string()
    .transform((val) => (typeof val === 'string' ? val.trim() : val))
    .nullish()
    .refine((value) => value && value.length > 0, {
      message: 'Card name is required',
    }),
  cardNumber: z
    .string()
    .transform((val) => (typeof val === 'string' ? val.trim() : val))
    .nullish()
    .refine((value) => value && value.length > 0, {
      message: 'Card number is required',
    }),
  cardExpiryMonth: z
    .string()
    .transform((val) => (typeof val === 'string' ? val.trim() : val))
    .nullish()
    .refine((value) => value && value.length > 0, {
      message: 'Card expiry month is required',
    }),
  cardExpiryYear: z
    .string()
    .transform((val) => (typeof val === 'string' ? val.trim() : val))
    .nullish()
    .refine((value) => value && value.length > 0, {
      message: 'Card expiry year is required',
    }),
  cardCVN: z
    .string()
    .transform((val) => (typeof val === 'string' ? val.trim() : val))
    .nullish()
    .refine((value) => value && value.length > 0, {
      message: 'Card CVN is required',
    }),
  amount: z
    .number()
    .min(1000, 'The deposit amount must be between $1000 and $3000')
    .max(3000, 'The deposit amount must be between $1000 and $3000')
    .optional()
    .refine((value) => value, {
      message: 'Deposit amount is required',
    }),
});

type FormSchema = z.infer<typeof PaymentSchema>;

interface MatchParams {
  orderNo: string;
}

export const OrderPay = React.memo(
  (props: RouteComponentProps<MatchParams>) => {
    const refreshAuthenticationDetails = useRefreshAuthenticationDetails();
    const [byoState, setByoState] = useRecoilState(BuildYourOwnState);
    const { orderNo } = props.match.params;
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [continueEnabled, setContinueEnabled] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState<string>();
    const [isSticky, setIsSticky] = useState(true);
    const parentRef = useRef<HTMLDivElement | null>(null);
    const stickyRef = useRef<HTMLDivElement | null>(null);
    const { control, handleSubmit, formState } = useForm<FormSchema>({
      resolver: zodResolver(PaymentSchema),
      defaultValues: { ...defaultPaymentInfo, orderReference: orderNo },
      mode: 'all',
    });

    useEffect(() => {
      const handleScroll = () => {
        const parentElement = parentRef.current;
        const stickyElement = stickyRef.current;

        if (parentElement && stickyElement) {
          const parentRect = parentElement.getBoundingClientRect();
          const stickyRect = stickyElement.getBoundingClientRect();

          // Check if the visible part of the parent is less than the height of the sticky component
          const isVisiblePartLess =
            parentRect.bottom - byoState.offsetHeight < stickyRect.height;

          setIsSticky(!isVisiblePartLess);
        }
      };

      handleScroll();
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const onLoad = useCallback(async () => {
      await refreshAuthenticationDetails();
      if (orderNo) {
        if (!byoState.buyer) {
          const buyer = await getBuyerDetails(orderNo, true);
          setByoState((prevState) => ({ ...prevState, buyer }));
        }
        if (!byoState.seller) {
          const seller = await getSellerDetails();
          setByoState((prevState) => ({ ...prevState, seller }));
        }
        if (byoState.buyer && byoState.seller) {
          setTimeout(() => window.scrollTo(0, 0));
          setIsLoading(false);
        }
      }
    }, [orderNo, byoState.buyer, byoState.seller]);

    useEffect(() => {
      onLoad();
      window['pushDigitalData']({
        event: '_pageLoaded',
      });
      if (document.referrer.includes('/build-your-own/order/details')) {
        window['pushDigitalData'](
          {
            event: '_formNavigate',
            form: {
              name: 'buy online',
              stage: 'submitted',
            },
          },
          true
        );
      }
    }, [onLoad]);

    const getContact = useCallback(() => {
      if (byoState?.buyer) {
        const contact = byoState?.buyer.contacts.filter((x) => x.isDefault);
        return contact && contact.length > 0 ? contact[0] : null;
      }
      return null;
    }, [byoState?.buyer]);

    const getAddresses = useCallback(() => {
      if (byoState?.buyer) {
        const addresses = byoState?.buyer.addresses.filter(
          (x) => x.isActive && x.isDefault
        );
        return addresses || [];
      }
      return [];
    }, [byoState?.buyer]);

    const handleFormSubmit = async (data: FormSchema) => {
      try {
        if (!byoState.eWayEncryptionKey || !window['eCrypt']) {
          setErrorMessage('Something went wrong. Service unavailable.');
          console.error('eCrypt is not available.');
          return;
        }

        if (typeof window !== 'undefined' && window['eCrypt']) {
          const encryptedCardNumber = window['eCrypt'].encryptValue(
            data.cardNumber,
            byoState.eWayEncryptionKey
          );
          const encryptedCardCVN = window['eCrypt'].encryptValue(
            data.cardCVN,
            byoState.eWayEncryptionKey
          );

          const encryptedData = {
            ...data,
            cardNumber: encryptedCardNumber,
            cardCVN: encryptedCardCVN,
          };
          setErrorMessage('');
          setSuccessMessage('');
          setIsSubmitted(true);
          await postDeposit(encryptedData).then(async (res) => {
            if (res) {
              if (res.success) {
                setSuccessMessage(res.message);
                setContinueEnabled(true);
                window['pushDigitalData'](
                  {
                    event: '_formNavigate',
                    form: {
                      name: 'buy online',
                      stage: 'submitted',
                    },
                  },
                  true
                );
                setIsSubmitted(false);
              } else {
                setErrorMessage(res.message || '');
                setIsSubmitted(false);
              }
            } else {
              setErrorMessage('Something went wrong');
              setIsSubmitted(false);
            }
          });
        }
      } catch {
        setErrorMessage('Something went wrong');
        console.error('Something went wrong on posting deposit');
        setIsSubmitted(false);
      }
    };

    const findOptionLabel = (
      options: Option[],
      value: string | null
    ): Option | undefined =>
      options?.find((option) => option.value === value) || undefined;

    return (
      <div className={styles.OrderPay}>
        {isSubmitted && <OverlayLoader />}
        <OrderBanner title="2. Pay Deposit" withReturnUrl />
        <div className={styles.OrderPayContent} ref={parentRef}>
          <div className={styles.OrderPayForm}>
            <h2>Refundable Order Fee</h2>
            <p>
              To place your on-line order, you will need to pay a refundable
              order fee of $1000. You can pay the refundable order fee using a
              credit card below. We accept Amex, Visa and Mastercard. If we
              accept your order, the order fee will be taken by us as a deposit
              on the purchase of your vehicle. If we do not accept your order we
              will refund the order fee to your credit card.
            </p>
            <p>
              Not quite ready to commit? We have a 24 hour cooling off period in
              which you can cancel or amend your order. So if you change your
              mind, we&apos;re here to help. This right is in addition to any
              statutory rights you may have to cancel your order.
            </p>
            <h3>Merchant Details</h3>
            <div className="grid grid-cols-12 gap-x-4 gap-y-4">
              <div className="col-span-3">
                <strong>Name: </strong>
              </div>
              <div className="col-span-9">
                {byoState.seller?.name} &#40;ABN: {byoState.seller?.abn}
                &#41;
              </div>
              <div className="col-span-3">
                <strong>Details: </strong>
              </div>
              <div className="col-span-9">{byoState.seller?.details}</div>
            </div>
            {isLoading ? (
              <Loading height="100%" />
            ) : (
              <>
                <h3>Customer Details</h3>
                <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                  <div className="col-span-3">
                    <strong>Name: </strong>
                  </div>
                  <div className="col-span-9">{getContact()?.fullName}</div>
                  {byoState?.buyer?.type === EntityType.Company && (
                    <>
                      <div className="col-span-3">
                        <strong>Company: </strong>
                      </div>
                      <div className="col-span-9">
                        representing {byoState?.buyer?.name}
                      </div>
                      {getContact()?.jobTitle && (
                        <>
                          <div className="col-span-3">
                            <strong>Job Title: </strong>
                          </div>
                          <div className="col-span-9">
                            as {getContact()?.jobTitle}
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {getAddresses().map((a, idx) => (
                    <Fragment key={idx}>
                      <div className="col-span-3">
                        <strong>Address: </strong>
                      </div>
                      <div className="col-span-9">
                        <p>{a.address1}</p>
                        {a.address2 && <p>{a.address2}</p>}
                        <p>{`${a.city}, ${a.stateCode}, ${a.postalCode}`}</p>
                      </div>
                    </Fragment>
                  ))}
                </div>
                <h3>Credit Card Details</h3>
                {byoState.eWayEndpoint === 'Sandbox' && (
                  <div className="grid grid-cols-12 gap-x-4 gap-y-4">
                    <div className="col-span-12">
                      <p>
                        <strong>Test Credit Cards ONLY</strong>
                        <br />
                        <strong>Visa:</strong> 4444333322221111
                        <br />
                        <strong>MasterCard:</strong> 5105105105105100
                      </p>
                    </div>
                  </div>
                )}
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <div className="grid grid-cols-12 gap-4">
                    <Controller
                      name="cardName"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          autoComplete="off"
                          label="Card Name"
                          variant="outlined"
                          className={`col-span-${byoState.isMobile ? 12 : 6}`}
                          value={field.value || ''}
                          error={formState.errors?.cardName?.message}
                          disabled={continueEnabled}
                        />
                      )}
                    />
                    <Controller
                      name="cardNumber"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          autoComplete="off"
                          label="Card Number"
                          variant="outlined"
                          className={`col-span-${byoState.isMobile ? 12 : 6}`}
                          value={field.value || ''}
                          error={formState.errors?.cardNumber?.message}
                          disabled={continueEnabled}
                        />
                      )}
                    />
                    <Controller
                      name="cardCVN"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          autoComplete="off"
                          label="CVN"
                          variant="outlined"
                          className={`col-span-${byoState.isMobile ? 12 : 4}`}
                          value={field.value || ''}
                          error={formState.errors?.cardCVN?.message}
                          disabled={continueEnabled}
                        />
                      )}
                    />
                    <Controller
                      name="cardExpiryYear"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          label="Expiry Year"
                          isFormField
                          value={findOptionLabel(
                            yearOptions,
                            field.value || ''
                          )}
                          onChange={(option) =>
                            field.onChange(option?.value || '')
                          }
                          placeholder="Select year"
                          options={yearOptions || []}
                          className={cn(
                            `col-span-${byoState.isMobile ? 12 : 4}`,
                            styles.DropdownOverride
                          )}
                          error={formState.errors.cardExpiryYear?.message}
                          isDisabled={continueEnabled}
                        />
                      )}
                    />
                    <Controller
                      name="cardExpiryMonth"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          label="Expiry Month"
                          isFormField
                          value={findOptionLabel(
                            monthOptions,
                            field.value || ''
                          )}
                          onChange={(option) =>
                            field.onChange(option?.value || '')
                          }
                          placeholder="Select month"
                          options={monthOptions || []}
                          className={cn(
                            `col-span-${byoState.isMobile ? 12 : 4}`,
                            styles.DropdownOverride
                          )}
                          error={formState.errors.cardExpiryMonth?.message}
                          isDisabled={continueEnabled}
                        />
                      )}
                    />
                    <Controller
                      name="amount"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="number"
                          autoComplete="off"
                          label="Amount (AUD)"
                          variant="outlined"
                          className={`col-span-${byoState.isMobile ? 12 : 4}`}
                          value={field.value}
                          active
                          error={formState.errors?.amount?.message}
                          disabled={continueEnabled}
                          onChange={(e) => {
                            const value = e.currentTarget.value
                              ? Number(e.currentTarget.value)
                              : undefined;
                            field.onChange(value);
                          }}
                        />
                      )}
                    />
                  </div>
                  {errorMessage && (
                    <div className={styles.ErrorMessage}>{errorMessage}</div>
                  )}
                  {successMessage && (
                    <div className={styles.SuccessMessage}>
                      {successMessage}
                    </div>
                  )}
                  <div className={styles.SubmitGroup}>
                    <Button
                      nativeType="submit"
                      type="primary"
                      buttonSize="medium"
                      disabled={isSubmitted || continueEnabled}
                    >
                      Pay Now
                    </Button>
                    {continueEnabled && (
                      <Button
                        type="primary"
                        buttonSize="medium"
                        disabled={!continueEnabled}
                        href={`/${byoState.baseUrlSegment}/order/${byoState.orderNo}/contract`}
                      >
                        Continue
                      </Button>
                    )}
                  </div>
                </form>
              </>
            )}
          </div>
          <div className={styles.OrderSummaryContainer}>
            <StickyChild
              ref={stickyRef}
              className={cn(
                styles.OrderSummarySticky,
                styles[`OrderSummarySticky${isSticky ? 'Top' : 'Bottom'}`]
              )}
            >
              <OrderSummary />
            </StickyChild>
          </div>
        </div>
      </div>
    );
  }
);
