import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import cn from 'classnames';
import { BuildYourOwnState, IOrderBanner } from '../../store';
import { useRecoilValue } from 'recoil';
import { postLogout } from '~/common/services/build-your-own-service';

export const OrderBanner = React.memo((props: IOrderBanner) => {
  const { search, pathname } = useLocation();

  const byoState = useRecoilValue(BuildYourOwnState);
  const returnUrl = encodeURI(
    `/${byoState.baseUrlSegment}${pathname}${search}`
  );
  const loginUrl = `/${byoState.baseUrlSegment}/account/login?returnUrl=${returnUrl}`;

  const handleLogout = async () => {
    await postLogout().then((res) => {
      if (res.ok) {
        window.location.reload();
      } else {
        console.error('Failed to log out the user.');
      }
    });
  };

  return (
    <div className={styles.OrderBanner}>
      <div className={styles.Container}>
        <div className={styles.Title}>
          <p>{props.title}</p>
        </div>
        {byoState.isAuthenticated ? (
          <div className={cn(styles.AuthSection)}>
            <p>
              Welcome&nbsp;{byoState.userName},&nbsp;
              <span className={styles.Logout} onClick={handleLogout}>
                Logout
              </span>
            </p>
          </div>
        ) : (
          <div className={cn(styles.AuthSection)}>
            <p>
              Already Have An Account?&nbsp;
              <a href={loginUrl}>Login now</a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
});
