import * as React from 'react';
import cn from 'classnames';
import styles from './index.module.scss';
import 'intersection-observer';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  FloatingFocusManager,
  FloatingOverlay,
} from '@floating-ui/react';
import { SvgIcon } from '..';

interface DialogOptions {
  initialOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  root?: HTMLElement | null | React.MutableRefObject<HTMLElement | null>;
  rootId?: string;
  lockScroll?: boolean;
  outsidePress?: boolean;
}

export function useDialog({
  initialOpen = true,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  root,
  rootId,
  lockScroll,
  outsidePress,
}: DialogOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const data = useFloating({
    open,
    onOpenChange: setOpen,
  });

  const context = data.context;

  const click = useClick(context, {
    enabled: controlledOpen === null,
  });
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
    outsidePress: outsidePress === undefined ? true : outsidePress,
  });
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return React.useMemo(
    () => ({
      root,
      rootId,
      lockScroll,
      outsidePress,
      open,
      setOpen,
      ...interactions,
      ...data,
    }),
    [root, rootId, open, setOpen, interactions, data]
  );
}

type ContextType = ReturnType<typeof useDialog> | null;

const DialogContext = React.createContext<ContextType>(null);

export const useDialogContext = () => {
  const context = React.useContext(DialogContext);

  if (context === null) {
    throw new Error('Dialog components must be wrapped in <Dialog />');
  }

  return context;
};

export const Dialog = ({
  children,
  ...options
}: {
  children: React.ReactNode;
} & DialogOptions) => {
  const dialog = useDialog(options);
  return (
    <DialogContext.Provider value={dialog}>{children}</DialogContext.Provider>
  );
};

interface DialogTriggerProps {
  children: React.ReactNode;
  onClickComplete?: (e?: any) => void;
}

export const DialogTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & DialogTriggerProps
>(({ children, onClickComplete, className, ...props }, propRef) => {
  const context = useDialogContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  return (
    <div
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
      onClick={() => {
        context.setOpen(!context.open);
        if (onClickComplete) {
          onClickComplete(!context.open);
        }
      }}
      className={cn(styles.DialogTrigger, className)}
    >
      {children}
    </div>
  );
});

export const DialogContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement> & {
    contentClassName?: string;
    hideCloseButton?: boolean;
    disableFocusOnDialog?: boolean;
  }
>(
  (
    { hideCloseButton, disableFocusOnDialog, contentClassName, ...props },
    propRef
  ) => {
    const { context: floatingContext, ...context } = useDialogContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    const renderContent = () => (
      <div
        ref={ref}
        {...context.getFloatingProps(props)}
        className={cn(styles.DialogOverlayContent, contentClassName)}
      >
        {!hideCloseButton && <DialogClose />}
        {props.children}
      </div>
    );

    return (
      <FloatingPortal root={context.root} id={context.rootId}>
        <FloatingOverlay
          className={cn(
            {
              [styles.DialogOverlay]: !context.root,
              [styles.DialogOverlayRoot]: context.root,
            },
            props.className
          )}
          style={{
            position: context.root ? 'absolute' : 'fixed',
            visibility: floatingContext.open ? 'visible' : 'hidden',
            top: context.root ? '100%' : '50%',
            left: context.root ? '0px' : '50%',
            right: '0px',
            bottom: '0px',
            overflow: 'visible',
          }}
          lockScroll={context.lockScroll}
        >
          {!disableFocusOnDialog ? (
            <FloatingFocusManager context={floatingContext}>
              {renderContent()}
            </FloatingFocusManager>
          ) : (
            renderContent()
          )}
        </FloatingOverlay>
      </FloatingPortal>
    );
  }
);

const DialogClose = () => {
  const { setOpen } = useDialogContext();
  return (
    <SvgIcon
      className={styles.DialogClose}
      type="close"
      size={1.75}
      onClick={() => setOpen(false)}
    />
  );
};
