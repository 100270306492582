import React, { useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { OrderDetailsState } from '../../../store';
import { useRecoilValue } from 'recoil';
import {
  getDocument,
  getDocuments,
} from '~/common/services/build-your-own-service';
import { Loading } from '~/common/components/ui-elements';
import { FileInfo, OrderDetails } from '~/common/models';

interface FileInfoExtended extends FileInfo {
  url: string;
}

export const ProfileDocuments = React.memo(() => {
  const orderDetails = useRecoilValue(OrderDetailsState);
  const [isLoading, setIsLoading] = useState(true);
  const [documents, setDocuments] = useState<FileInfoExtended[]>();
  const { orderNo } = orderDetails || ({} as OrderDetails);

  const onLoad = useCallback(async () => {
    if (orderNo) {
      const _documents = await getDocuments(orderNo);
      const docs = await Promise.all(
        _documents.map(async (x) => {
          let url = '';
          if (x.name) {
            await getDocument(orderNo, x.name).then(async (res) => {
              if (res.ok) {
                const blob = await res.blob();
                url = URL.createObjectURL(blob);
              } else {
                console.error(
                  `Something went wrong on getting document ${x.name} on order ${orderNo}`
                );
              }
            });
          }
          return {
            ...x,
            lastModified: x.lastModified ? new Date(x.lastModified) : undefined,
            url,
          } as FileInfoExtended;
        })
      );
      setDocuments(docs);
      setIsLoading(false);
    }
  }, [orderNo]);

  useEffect(() => {
    onLoad();
    window['pushDigitalData']({
      event: '_pageLoaded',
    });
  }, [onLoad]);

  return (
    <div className={styles.ProfileDocuments}>
      <h2>My Documents</h2>
      {isLoading ? (
        <Loading height="100%" />
      ) : (
        <>
          {documents && documents.length > 0 ? (
            <>
              <p>
                All documents relating to the purchase of your brand new Subaru
                are available here.
              </p>
              {documents.map((x, idx) => (
                <div key={idx} className={styles.Row}>
                  <a
                    href={x.url}
                    target="_blank"
                    rel="noreferrer"
                    download={`${x.name}`}
                  >
                    {x.name}
                  </a>
                  <p>
                    {x.lastModified?.toLocaleDateString('en-US', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    })}
                  </p>
                </div>
              ))}
            </>
          ) : (
            <p>There are no documents related to your order.</p>
          )}
        </>
      )}
    </div>
  );
});
