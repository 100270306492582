import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { z, emailAddressSchema } from '~/common/utils/validations';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Input, SvgIcon } from '~/common/components/ui-elements';
import { colorPrimary } from '~/common/utils';
import { postAuthenticate } from '~/common/services/build-your-own-service';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useRecoilState } from 'recoil';
import { BuildYourOwnState } from '../../store';
import { OverlayLoader } from '../..';

type Nullish = string | undefined | null;
interface LoginInfo {
  email: Nullish;
  password: Nullish;
}

const defaultLoginInfo: LoginInfo = {
  email: '',
  password: '',
};

const LoginSchema = z.object({
  email: z
    .string()
    .transform((val) => (typeof val === 'string' ? val.trim() : val))
    .nullish()
    .refine((value) => value && value.length > 0, {
      message: 'Email address is required',
    })
    .refine((value) => emailAddressSchema.safeParse(value).success),
  password: z
    .string()
    .transform((val) => (typeof val === 'string' ? val.trim() : val))
    .nullish()
    .refine((value) => value && value.length > 0, {
      message: 'Password is required',
    }),
});

type FormSchema = z.infer<typeof LoginSchema>;

export const AccountLogin = React.memo(() => {
  const [byoState] = useRecoilState(BuildYourOwnState);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  const normalizedParams = Object.fromEntries(
    Object.entries(searchParams).map(([key, value]) => [
      key.toLowerCase(),
      value,
    ])
  );
  const returnUrl = normalizedParams['returnurl'] as string | undefined;
  const { control, handleSubmit, formState } = useForm<FormSchema>({
    resolver: zodResolver(LoginSchema),
    defaultValues: defaultLoginInfo,
    mode: 'all',
  });

  useEffect(() => {
    window['pushDigitalData']({
      event: '_pageLoaded',
    });
    if (document.referrer.includes('/build-your-own/account/login')) {
      window['pushDigitalData'](
        {
          event: '_formNavigate',
          form: {
            name: 'buy online',
            stage: 'submitted',
          },
        },
        true
      );
    }
  }, []);

  const handleFormSubmit = async (data: FormSchema) => {
    try {
      setErrorMessage('');
      setIsSubmitted(true);

      const result = await postAuthenticate(data);
      if (result.success) {
        if (returnUrl) {
          window.location.href = returnUrl;
        } else {
          window.location.href = `/${byoState.baseUrlSegment}/profile`; // dashboard
        }
      } else {
        setErrorMessage(result.message || 'Something went wrong');
        setIsSubmitted(false);
      }
    } catch {
      setErrorMessage('Something went wrong');
      console.error('Something went wrong on login');
      setIsSubmitted(false);
    }
  };

  return (
    <div className={styles.AccountLogin}>
      {isSubmitted && <OverlayLoader />}
      <div className={styles.Container}>
        <div className={styles.FormContainer}>
          <h2>Login</h2>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    autoComplete="off"
                    label="Email address"
                    variant="outlined"
                    className="col-span-12"
                    value={field.value || ''}
                    error={formState.errors?.email?.message}
                  />
                )}
              />
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    autoComplete="off"
                    type={showPassword ? 'text' : 'password'}
                    label="Password"
                    variant="outlined"
                    className="col-span-12"
                    endAdornment={
                      <SvgIcon
                        style={{ cursor: 'pointer' }}
                        color={`#${colorPrimary}`}
                        type={showPassword ? 'eyeClosed' : 'eye'}
                        onClick={() => setShowPassword(!showPassword)}
                      />
                    }
                    value={field.value || ''}
                    error={formState.errors?.password?.message}
                  />
                )}
              />
            </div>
            {errorMessage && (
              <div className={styles.ErrorMessage}>{errorMessage}</div>
            )}
            <div className={styles.SubmitGroup}>
              <Button
                nativeType="submit"
                type="primary"
                buttonSize="medium"
                disabled={isSubmitted}
              >
                Log in
              </Button>
              <Button
                type="text"
                href="/build-your-own/account/recover"
                title="Restore password"
                disabled={isSubmitted}
                tabIndex={-1}
              >
                Forgot password?
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});
