import React, { forwardRef, useEffect, useState } from 'react';
import { MutableSnapshot, RecoilRoot } from 'recoil';
import { PageLayout } from '~/common/components';
import { StaticRouter, BrowserRouter, Switch, Route } from 'react-router-dom';
import styles from './index.module.scss';
import cn from 'classnames';
import { BuildYourOwnState } from './store';
import { OrderDetails } from './components/order-details';
import { useMediaQuery } from 'react-responsive';
import { colorWhite, tabletMax } from '~/common/utils';
import { useNavOffsetHeight } from '~/common/hooks/use-navOffsetHeight';
import { OrderPay } from './components/order-pay';
import { AccountLogin } from './components/account-login';
import { Entity } from '~/common/models';
import { AccountRecover } from './components/account-recover';
import { AccountPassword } from './components/account-password';
import { OrderContract } from './components/order-contract';
import { OrderThankYou } from './components/order-thank-you';
import { Profile } from './components/profile';
import { FadeLoader } from 'react-spinners';

interface RootComponentProps {
  context: any;
  location: string;
  baseUrlSegment: string;
  isAuthenticated: boolean;
  userName: string;
  documentBaseUrl: string;
  eWayEndpoint: string;
  eWayEncryptionKey: string;
  googleMapsApiKey: string;
  seller?: Entity;
  buyer?: Entity;
  orderNo: string;
}

export const isOrderNo = (refCode: string) => {
  const regex = /^\d{8}-\d{4}$/;
  return regex.test(refCode);
};

export const RootComponent = (props: RootComponentProps) => {
  const [isMobile, setIsMobile] = useState(
    useMediaQuery(
      { query: `(max-width: ${tabletMax}px)` },
      undefined,

      (matches) => {
        setIsMobile(matches);
      }
    )
  );

  const defaultHeightOffset = () => (isMobile ? 55 : 85);
  const offsetHeight = useNavOffsetHeight(isMobile, defaultHeightOffset());

  const initialize = ({ set }: MutableSnapshot) => {
    set(BuildYourOwnState, {
      ...props,
      isMobile,
      offsetHeight,
      id: '',
    });
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      if (document.referrer.includes('/build-your-own/account/login')) {
        window['pushDigitalData'](
          {
            event: '_formNavigate',
            form: {
              name: 'login',
              stage: 'submitted',
            },
          },
          true
        );
      } else if (
        document.referrer.includes('/build-your-own/account/password')
      ) {
        window['pushDigitalData'](
          {
            event: '_formNavigate',
            form: {
              name: 'password',
              stage: 'submitted',
            },
          },
          true
        );
      }
    }
  }, []);

  const app = (
    <RecoilRoot initializeState={initialize}>
      <PageLayout>
        <div className={styles.Container}>
          <div className={styles.Content}>
            <Switch>
              <Route path="/account/login" component={AccountLogin} />
              <Route path="/account/recover" component={AccountRecover} />
              <Route path="/account/password" component={AccountPassword} />
              <Route path="/order/details" component={OrderDetails} />
              <Route path="/order/:orderNo/pay" component={OrderPay} />
              <Route
                path="/order/:orderNo/contract"
                component={OrderContract}
              />
              <Route
                path="/order/:orderNo/thank-you"
                component={OrderThankYou}
              />
              <Route
                path={[
                  '/profile',
                  '/profile/pay-balance',
                  '/profile/trade-in',
                  '/profile/documents',
                  '/profile/my-account',
                ]}
                component={Profile}
              />
            </Switch>
          </div>
        </div>
      </PageLayout>
    </RecoilRoot>
  );

  if (typeof window === 'undefined') {
    return (
      <StaticRouter
        basename={`/${props.baseUrlSegment}`}
        context={props.context}
        location={props.location}
      >
        {app}
      </StaticRouter>
    );
  }

  return (
    <BrowserRouter basename={`/${props.baseUrlSegment}`}>{app}</BrowserRouter>
  );
};

interface StickyChildProps {
  className?: string;
  children: React.ReactNode;
}

export const StickyChild = forwardRef<HTMLDivElement, StickyChildProps>(
  (props, ref) => (
    <div ref={ref} className={props.className}>
      {props.children}
    </div>
  )
);

interface OverlayLoaderProps {
  title?: string;
}
export const OverlayLoader = React.memo(({ title }: OverlayLoaderProps) => (
  <div className={styles.Overlay}>
    <div className={styles.SpinnerContainer}>
      <FadeLoader color={`#${colorWhite}`} />
      <h3>{title || 'Submitting... Please hold on a moment.'}</h3>
    </div>
  </div>
));
