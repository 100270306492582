import React from 'react';
import { Modal } from '~/common/components/ui-elements';
import styles from './index.module.scss';

interface Props {
  title: string;
  closeModal: () => void;
}

export const OrderDisclaimerRDP = React.memo<Props>(({ title, closeModal }) => {
  return (
    <Modal closeModal={closeModal} lockScroll>
      <div className={styles.OrderDisclaimerRDP}>
        <h3>{title}</h3>
        <p>
          Price is the manufacturer&apos;s recommended retail driveaway price
          &#40;RDP&#41; and includes the vehicle price, 12 months registration,
          stamp duty, luxury car tax &#40;if applicable&#41;, CTP insurance cost
          estimate &#40;based on private purchaser with good driving record&#41;
          and a recommended charge for dealer delivery. The quoted RDP is an
          estimate based on the postcode selected by you. RDP may change based
          on location. RDP is not applicable to business or government buyers.
          Final on road costs may vary according to individual circumstances and
          actual driveaway price may vary between Retailers. Ask your Retailer
          for confirmation of their driveaway price.
        </p>
      </div>
    </Modal>
  );
});
