export interface AccountDetails {
  orderReference?: string | null;
  account: Account;
  address: Address;
  dealerCode?: string | null;
  accept12MonthsRoadsideAssistance?: boolean;
  agreePrivacyCollection?: boolean;
}

export interface Account {
  fullName?: string | null;
  isCompany: boolean;
  opInEmailMarketing: boolean;
  opInPostMarketing: boolean;
  opInPhoneAndSmsMarketing: boolean;
  id: number;
  flags: number;
  companyName?: string | null;
  companyAbn?: string | null;
  jobTitle?: string | null;
  prefix?: string | null;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNo?: string | null;
  dateOfBirth: Date | null;
  isAuthenticated?: boolean;
  emailConfirm?: string | null;
  password?: string | null;
  passwordConfirm?: string | null;
  addresses: Address[];
  licence: FileList | null;
}

enum ObjectStateFlags {
  Undefined = 0,
  Active = 1,
  Default = 2,
  Error = 4,
}

interface Address {
  id: number;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  stateCode?: string | null;
  postalCode?: string | null;
  countryCode: string;
  geoLatitude?: number | null;
  geoLongitude?: number | null;
  userId: number;
  isActive: boolean;
  isDefault: boolean;
}

export interface CarBuild {
  order: Order;
  view: SuburbView;
}

export interface Order {
  vin?: string;
  engineNumber?: string;
  etaDate?: string;
  model?: VehicleModel;
  variant?: VehicleVariant;
  colorOption?: ColorOption;
  interiorOption?: InteriorOption;
  accessoryOptions?: Accessory[];
  accessoryPackOptions?: AccessoryPack[];
  transmissionOption?: TransmissionOption;
  variantOption?: VariantOption;
  extendedWarranties?: ExtendedWarranty[];
  cappedServiceDetails?: CappedServiceDetail;
  prices?: Pricing;
}

interface VehicleModel {
  id: number;
  name: string;
  price: number;
  geoPrice: number;
  offersUrl: string;
  heroColorName: string;
  gfvAvailable: boolean;
  userCanBuy: boolean;
  isPreOrder?: boolean;
}

interface VehicleVariant {
  vehicleVariantId: string;
  id: number;
  name: string;
  price: number;
  geoPrice: number;
  bodyStyle: string;
  offersUrl: string;
  features?: string[];
  combFuelCycle: number;
  engineCapacity: number;
  gvr: string;
  isElectric: boolean;
  isHybrid: boolean;
  cylinders: number;
  tareWeight: number;
  insurerCode: string;
  dealerDeliveryFee: number;
  transmission: string;
  displayName: string;
  fhiCode: string;
  modelCode: string;
  year: string;
  fuel: string;
  optCode: string;
  isDriveaway: boolean;
  driveAwayPrice?: number;
  isSpecialEdition: boolean;
}

interface ColorOption {
  name: string;
  hexColorCode: string;
  vehicleColorCode: string;
  priceEffect: number;
}

interface InteriorOption {
  name: string;
  imageSrc: string;
  trimCode: string;
  priceEffect: number;
}

interface Accessory {
  itemCode: string;
  imageSrc: string;
  description: string;
  price: number;
  fittingTime: number;
  category: string;
  kitCode: string;
}

interface AccessoryPack {
  itemCode: string;
  description: string;
  price: number;
  imageSrc: string;
  accessories?: Accessory[];
  kitCode: string;
}

interface TransmissionOption {
  name: string;
  modelCode: string;
  variantId: number;
  accessoryPackOptions?: AccessoryPack[];
  cappedServiceDetails?: CappedServiceDetail;
}

interface VariantOption {
  name: string;
  optionCode: string;
  variantId: number;
  modelCode: string;
  priceEffect: number;
}

interface ExtendedWarranty {
  description: string;
  price: number;
}

interface CappedService {
  description: string;
  price: number;
}

interface CappedServiceDetail {
  cappedServices?: CappedService[];
  termsAndConditionsUrl: string;
  numberOfYears: number;
  recommendedNumberOfYears: number;
  recommendedNumberOfKms: number;
}

interface Pricing {
  accessoryPrice: number;
  administrationFee: number;
  ctpAdminLevy: number;
  ctpAmount: number;
  ctpDuty: number;
  ctpFee: number;
  ctpGst: number;
  ctpZone: string;
  dealerDeliveryFee: number;
  emergencyServicesLevy: number;
  fireLevy: number;
  generalIssueNumberPlateFee: number;
  hybridDiscount: number;
  incentive: number;
  lct: number;
  lifetimeSupportFund: number;
  mlp: number;
  maibAmount: number;
  maibCharge: number;
  maibGst: number;
  motorTax: number;
  optionPrice: number;
  prescribedFlatFee: number;
  recordingFee: number;
  registrationAmount: number;
  registrationFee: number;
  registrationPlates: number;
  riskZone: string;
  roadSafetyLevy: number;
  stampDuty: number;
  success: boolean;
  tacAmount: number;
  tacCharge: number;
  tacDuty: number;
  tacGst: number;
  tioAmount: number;
  tioCharge: number;
  tioGst: number;
  totalRegistration: number;
  trafficImprovementFee: number;
  dap: number;
  vehicleTax: number;
  basePrice: number;
  totalGst: number;
}

interface SuburbView {
  suburb: Suburb;
  // buildEmailTo: any;
  tradeIn: string;
  dealers: Dealer[];
}

interface Suburb {
  postcode: string;
  state: string;
  name: string;
}

interface Dealer {
  type: string;
  location: string;
  code: string;
  name: string;
  distance: string;
  isMobileDealer: boolean;
}

export interface OrderDetails {
  orderNo: string;
  orderStatus: OrderStatus;
  widget: Widget;
  accountDetails: Account;
  carDetails: CarDetails;
  dealerDetails: DealerDetails;
  financeDetails: FinanceDetails;
  financeQuoteDetails: FinanceQuoteDetails;
  showGetFinanceQuote: boolean;
  hasOrder: boolean;
  hasCarDetails: boolean;
  hasDealerDetails: boolean;
  hasFinanceDetails: boolean;
  financeUrl: string;
}

interface CarDetails {
  title: string;
  modelCodeForTestDrive: string;
  options: string[];
  vin: string;
  isPreOrder?: boolean;
}

interface DealerDetails {
  id: number;
  displayName: string;
  lmct: string;
  phoneNumber: string;
  address: Address;
  websiteName: string;
  websiteUrl: string;
  hasWebsiteUrl: boolean;
}

interface FinanceDetails {
  manufacturerListPrice: number;
  dealerDelivery: number;
  registration: number;
  ctp: number;
  tac: number;
  maib: number;
  stampDuty: number;
  luxuryCarTax: number;
  finalDap: number;
  baseDap: number;
  accessories: CarOption[];
  accessoryOnRoadCosts: CarOption;
  extendedWarranty: CarOption;
  cappedService: CarOption;
  interiorOption: CarOption;
  interiorOptionOnRoadCosts: CarOption;
  exteriorOption: CarOption;
  exteriorOptionOnRoadCosts: CarOption;
  deposit: number;
  additionalPayment: number;
  totalAmountOwing: number;
  hasAccessories: boolean;
  hasLuxuryCarTax: boolean;
  hasCtp: boolean;
  hasTac: boolean;
  hasMaib: boolean;
  hasExtendedWarranty: boolean;
  hasCappedService: boolean;
  hasInteriorOptionPriceEffect: boolean;
  hasExteriorOptionPriceEffect: boolean;
  accessoriesTotal: number;
}

interface CarOption {
  name: string;
  price: number;
}

interface FinanceQuoteDetails {
  carPrice: string;
  financeDepositPrice: string;
  balloonResidualPrice: string;
  termInMonths: string;
  repaymentFrequency: string;
  financeQuoteStateName: string;
  nvicCode: string;
}

interface Widget {
  orderNo: string;
  orderDisplayStatus: OrderDisplayStatus;
  financeStatus: FinanceStatus;
  carImageFullUrl: string;
  showFinanceStatus: boolean;
}

export enum FinanceStatus {
  NotApplicable = 0,
  Pending = 1,
  Approved = 2,
  Denied = 3,
}

export const FinanceStatusDescription: {
  [key in FinanceStatus]: string;
} = {
  [FinanceStatus.NotApplicable]: '',
  [FinanceStatus.Pending]: 'Pending',
  [FinanceStatus.Approved]: 'Approved',
  [FinanceStatus.Denied]: 'Denied',
};

export enum OrderStatus {
  Cancelled = 0,
  New = 1,
  DepositPaid = 17,
  ContractAccepted = 33,
  Approved = 65,
  Allocated = 129,
  InTransit = 3713,
  Delivered = 4225,
  AwaitingPayment = 16384,
  OrderCompleted = -32768,
}

export enum OrderDisplayStatus {
  Invalid = 0,
  DepositRequired = 1,
  ContractPending = 2,
  ContractAccepted = 3,
  Approved = 4,
  VehicleAllocated = 5,
  VehicleOnWater = 6,
  VehicleOnShore = 7,
  BalanceOwing = 8,
  ShippingToDealer = 9,
  ArrivedAtDealer = 10,
  Complete = 11,
  Cancelled = 12,
}

export const OrderDisplayStatusDescription: {
  [key in OrderDisplayStatus]: string;
} = {
  [OrderDisplayStatus.Invalid]: 'Invalid',
  [OrderDisplayStatus.DepositRequired]: 'Deposit Required',
  [OrderDisplayStatus.ContractPending]:
    'Awaiting Customer Acknowledgement of Contract Terms',
  [OrderDisplayStatus.ContractAccepted]: 'Pending acceptance by Subaru',
  [OrderDisplayStatus.Approved]: 'Accepted',
  [OrderDisplayStatus.VehicleAllocated]: 'Vehicle Allocated',
  [OrderDisplayStatus.VehicleOnWater]: 'On Water',
  [OrderDisplayStatus.VehicleOnShore]: 'On Shore',
  [OrderDisplayStatus.BalanceOwing]: 'Balance Owing',
  [OrderDisplayStatus.ShippingToDealer]: 'Shipping to Dealer',
  [OrderDisplayStatus.ArrivedAtDealer]: 'Arrived at Dealer',
  [OrderDisplayStatus.Complete]: 'Order confirmed. Delivery pending.',
  [OrderDisplayStatus.Cancelled]: 'Cancelled',
};

enum ContactMethodFlags {
  Active = ObjectStateFlags.Active,
  Default = ObjectStateFlags.Default,
  Email = 0x10,
  Phone = 0x20,
  Fax = 0x30,
  Web = 0x40,
}

export enum ContactMethodType {
  Undefined = 0,
  Email = ContactMethodFlags.Email,
  Phone = ContactMethodFlags.Phone,
  Fax = ContactMethodFlags.Fax,
  Web = ContactMethodFlags.Web,
}

export enum EntityFlags {
  Active = ObjectStateFlags.Active,
  Business = 0x10,
  Seller = 0x20,
  Dealer = 0x40,
  Buyer = 0x80,
}

export enum EntityType {
  Seller = EntityFlags.Seller | EntityFlags.Business,
  Dealer = EntityFlags.Dealer | EntityFlags.Business,
  Private = EntityFlags.Buyer,
  Company = EntityFlags.Buyer | EntityFlags.Business,
}

enum ContactFlags {
  Active = ObjectStateFlags.Active,
  Default = ObjectStateFlags.Default,
}

export interface Entity {
  isActive: boolean;
  type: EntityType;
  location: string;
  distanceKm?: number;
  id: number;
  flags: EntityFlags;
  code: string;
  abn: string;
  licenseNumber: string;
  name: string;
  details: string;
  addresses: Address[];
  contacts: Contact[];
  isMobileDealer?: boolean;
}

interface Contact {
  isActive: boolean;
  isDefault: boolean;
  fullName: string;
  id: number;
  flags: ContactFlags;
  jobTitle: string;
  prefix: string;
  firstName: string;
  middleName: string;
  lastName: string;
  entityId: number;
  contactMethods: ContactMethod[];
}

interface ContactMethod {
  isActive: boolean;
  isDefault: boolean;
  type: ContactMethodType;
  id: number;
  flags: ContactMethodFlags;
  name: string;
  value: string;
  contactId: number;
}

export interface ContractDetails {
  orderReference: string;
  isFinanceApproval: boolean;
  isOther: boolean;
  notes?: string | null;
  contractAccepted: boolean;
}

export interface FileInfo {
  name?: string;
  size: number;
  lastModified?: Date;
  path?: string;
}
