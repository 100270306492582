import {
  CarBuild,
  AccountDetails,
  OrderDetails,
  Entity,
  ContractDetails,
  FileInfo,
} from '../models';

interface BaseResult {
  success: boolean;
  message?: string;
}
interface AccountResult extends BaseResult {
  orderNo?: string;
}
interface PaymentResult extends BaseResult {
  receiptNumber?: string;
}

interface AuthenticationDetails {
  isAuthenticated: boolean;
  userName: string;
}

export const getAuthenticationDetails = (): Promise<AuthenticationDetails> =>
  fetch(`/api/build-your-own/authentication-details`, {
    method: 'GET',
    cache: 'no-cache', // prevent caching
  })
    .then((res) => res.json())
    .then((res) => res as AuthenticationDetails);

export const getAccountDetails = (): Promise<AccountDetails> =>
  fetch(`/api/build-your-own/account-details`, {
    method: 'GET',
    cache: 'no-cache', // prevent caching
  })
    .then((res) => res.json())
    .then((res) => res as AccountDetails);

export const postLogout = (): Promise<Response> =>
  fetch(`/api/build-your-own/logout`, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
  }).then((res) => res);

export const postRecover = (data: Record<string, any>): Promise<BaseResult> =>
  fetch(`/api/build-your-own/recover`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => res);

export const postAuthenticate = (
  data: Record<string, any>
): Promise<BaseResult> =>
  fetch(`/api/build-your-own/authenticate`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => res);

export const postPassword = (data: Record<string, any>): Promise<Response> =>
  fetch(`/api/build-your-own/password`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((res) => res);

export const postAccountDetails = (
  id: string,
  data: FormData // use FormData since we need to pass the licence file
): Promise<AccountResult> =>
  fetch(`/api/build-your-own/account-details?id=${id}`, {
    method: 'POST',
    body: data,
  })
    .then((res) => {
      if (!res.ok) {
        return res.json().then((errorData) => {
          const errorMessages = Object.values(errorData.errors || {})
            .flat()
            .join(', ');
          return {
            success: false,
            message: errorMessages || 'An unknown error occurred.',
          } as AccountResult;
        });
      }
      return res.json();
    })
    .then((res) => res);

export const postDeposit = (
  data: Record<string, any>
): Promise<PaymentResult> =>
  fetch(`/api/build-your-own/deposit`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => res);

export const getCarBuild = (id: string): Promise<CarBuild> =>
  fetch(`/api/build-your-own/car-build?id=${id}`)
    .then((res) => res.json())
    .then((res) => res as CarBuild);

export const getOrderDetails = (orderNo: string): Promise<Response> =>
  fetch(`/api/build-your-own/order-details?orderNo=${orderNo}`).then(
    (res) => res
  );

export const getOrderDetailsByRef = (
  orderReference: string
): Promise<OrderDetails> =>
  fetch(
    `/api/build-your-own/order-details-by-ref?orderReference=${orderReference}`
  )
    .then((res) => res.json())
    .then((res) => res as OrderDetails);

export const getSellerDetails = (): Promise<Entity> =>
  fetch(`/api/build-your-own/seller-details`)
    .then((res) => res.json())
    .then((res) => res as Entity);

export const getBuyerDetails = (
  orderNo: string,
  full?: boolean
): Promise<Entity> =>
  fetch(
    `/api/build-your-own/buyer-details?orderNo=${orderNo}&full=${full || false}`
  )
    .then((res) => res.json())
    .then((res) => res as Entity);

export const getDealerDetails = (
  orderNo: string,
  full?: boolean
): Promise<Entity> =>
  fetch(
    `/api/build-your-own/dealer-details?orderNo=${orderNo}&full=${
      full || false
    }`
  )
    .then((res) => res.json())
    .then((res) => res as Entity);

export const getDocuments = (orderNo: string): Promise<FileInfo[]> =>
  fetch(`/api/build-your-own/documents?orderNo=${orderNo}`)
    .then((res) => res.json())
    .then((res) => res as FileInfo[]);

export const getContractDetails = (orderNo: string): Promise<ContractDetails> =>
  fetch(`/api/build-your-own/contract-details?orderNo=${orderNo}`)
    .then((res) => res.json())
    .then((res) => res as ContractDetails);

export const getDocument = (orderNo: string, file: string): Promise<Response> =>
  fetch(`/api/build-your-own/document?orderNo=${orderNo}&file=${file}`).then(
    (res) => res
  );

export const getContract = (
  orderNo: string,
  format = 'html'
): Promise<Response> =>
  fetch(
    `/api/build-your-own/contract?orderNo=${orderNo}&format=${format}`
  ).then((res) => res);

export const postContractNotes = (
  orderNo: string,
  text: string
): Promise<Response> =>
  fetch(`/api/build-your-own/contract-notes?orderNo=${orderNo}&text=${text}`, {
    method: 'POST',
  }).then((res) => res);

export const postContract = (
  orderNo: string,
  data: Record<string, any>
): Promise<Response> =>
  fetch(`/api/build-your-own/contract?orderNo=${orderNo}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then((res) => res);

export const getProfileOrders = (): Promise<string[]> =>
  fetch(`/api/build-your-own/profile-orders`)
    .then((res) => res.json())
    .then((res) => res as string[]);
