import { atom, useRecoilCallback } from 'recoil';
import {
  CarBuild,
  OrderDetails,
  AccountDetails,
  Entity,
} from '~/common/models';
import { getAuthenticationDetails } from '~/common/services/build-your-own-service';

interface IBuildYourOwn {
  baseUrlSegment: string;
  isAuthenticated: boolean;
  userName: string;
  isMobile: boolean;
  offsetHeight: number;
  documentBaseUrl: string;
  eWayEndpoint: string;
  eWayEncryptionKey: string;
  googleMapsApiKey: string;
  id: string;
  orderNo?: string;
  seller?: Entity;
  buyer?: Entity;
  dealer?: Entity;
}

export interface IOrderBanner {
  title: string;
  withReturnUrl: boolean;
}

export const BuildYourOwnState = atom<IBuildYourOwn>({
  key: 'BuildYourOwn',
  default: {
    baseUrlSegment: 'build-your-own',
    isAuthenticated: false,
    userName: '',
    isMobile: false,
    offsetHeight: 0,
    documentBaseUrl: '',
    eWayEndpoint: 'Sandbox',
    eWayEncryptionKey: '',
    googleMapsApiKey: '',
    id: '',
  },
});

export const AccountDetailsState = atom<AccountDetails | null>({
  key: 'AccountDetails',
  default: null,
});

export const CarBuildState = atom<CarBuild | null>({
  key: 'CarBuild',
  default: null,
});

export const OrderDetailsState = atom<OrderDetails | null>({
  key: 'OrderDetails',
  default: null,
});

export const useRefreshAuthenticationDetails = () => {
  return useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const byoState = snapshot.getLoadable(BuildYourOwnState).getValue();
        const res = await getAuthenticationDetails();
        if (res) {
          const updatedState = { ...byoState, ...res };
          set(BuildYourOwnState, updatedState);
          return updatedState;
        }
        return byoState;
      },
    []
  );
};
