import React, { useCallback, useEffect, useRef, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styles from './index.module.scss';
import cn from 'classnames';
import {
  BuildYourOwnState,
  useRefreshAuthenticationDetails,
} from '../../store';
import { OrderBanner } from '../order-banner';
import { OrderSummary } from '../order-summary';
import { useRecoilValue } from 'recoil';
import { StickyChild, OverlayLoader } from '../..';
import {
  Button,
  Checkbox,
  Loading,
  SvgIcon,
  TextArea,
} from '~/common/components/ui-elements';
import {
  getContract,
  getContractDetails,
  postContract,
  postContractNotes,
} from '~/common/services/build-your-own-service';
import { ContractDetails } from '~/common/models';
import { z } from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { trueOnly } from '~/common/utils/validations';
import { colorPrimary } from '~/common/utils';

const defaultContractDetails: ContractDetails = {
  orderReference: '',
  isFinanceApproval: false,
  isOther: false,
  notes: '',
  contractAccepted: false,
};

const ContractSchema = z.object({
  orderReference: z
    .string()
    .transform((val) => (typeof val === 'string' ? val.trim() : val))
    .nullish()
    .refine((value) => value && value.length > 0, {
      message: 'Order reference is required',
    }),
  isFinanceApproval: z.boolean(),
  isOther: z.boolean(),
  notes: z
    .string()
    .transform((val) => (typeof val === 'string' ? val.trim() : val))
    .nullish(),
  contractAccepted: trueOnly('You must agree to the Contract of Sale'),
});

type FormSchema = z.infer<typeof ContractSchema>;

interface MatchParams {
  orderNo: string;
}

export const OrderContract = React.memo(
  (props: RouteComponentProps<MatchParams>) => {
    const refreshAuthenticationDetails = useRefreshAuthenticationDetails();
    const byoState = useRecoilValue(BuildYourOwnState);
    const { orderNo } = props.match.params;
    const [isLoading, setIsLoading] = useState(true);
    const [isPreviewLoading, setIsPreviewLoading] = useState(true);
    const [previewErrorMessage, setPreviewErrorMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [content, setContent] = useState<string>();
    const [pdfContent, setPdfContent] = useState<string>();
    const [contentType, setContentType] = useState<string | null>(null);
    const [isSticky, setIsSticky] = useState(true);
    const parentRef = useRef<HTMLDivElement | null>(null);
    const stickyRef = useRef<HTMLDivElement | null>(null);
    const { control, handleSubmit, formState, reset, watch, setValue } =
      useForm<FormSchema>({
        resolver: zodResolver(ContractSchema),
        defaultValues: { ...defaultContractDetails, orderReference: orderNo },
        mode: 'all',
      });

    const isOther = watch('isOther');
    const isFinanceApproval = watch('isFinanceApproval');
    const notes = watch('notes');

    useEffect(() => {
      const handleScroll = () => {
        const parentElement = parentRef.current;
        const stickyElement = stickyRef.current;

        if (parentElement && stickyElement) {
          const parentRect = parentElement.getBoundingClientRect();
          const stickyRect = stickyElement.getBoundingClientRect();

          // Check if the visible part of the parent is less than the height of the sticky component
          const isVisiblePartLess =
            parentRect.bottom - byoState.offsetHeight < stickyRect.height;

          setIsSticky(!isVisiblePartLess);
        }
      };

      handleScroll();
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    const onLoad = useCallback(async () => {
      await refreshAuthenticationDetails();
      if (orderNo) {
        const contractDetails = await getContractDetails(orderNo);
        reset(contractDetails);
        await previewContract();
        window.scrollTo(0, 0);
        setIsLoading(false);
      }
    }, [orderNo]);

    useEffect(() => {
      onLoad();
      window['pushDigitalData']({
        event: '_pageLoaded',
      });
    }, [onLoad]);

    const handleFormSubmit = async (data: FormSchema) => {
      try {
        setErrorMessage('');
        setIsSubmitted(true);
        await postContract(orderNo, data).then(async (res) => {
          if (res.ok) {
            window.location.href = `/${byoState.baseUrlSegment}/order/${orderNo}/thank-you`;
          } else if (res.status === 404) {
            setErrorMessage('Order not found');
            setIsSubmitted(false);
          } else {
            throw new Error('Something went wrong on accepting contract');
          }
        });
      } catch {
        setErrorMessage('Something went wrong');
        console.error('Something went wrong on accepting contract');
        setIsSubmitted(false);
      }
    };

    const previewContract = async () => {
      try {
        await getContract(orderNo, 'html').then(async (res) => {
          if (res.ok) {
            const _contentType = res.headers.get('Content-Type');
            setContentType(_contentType);
            const html = await res.text();
            setContent(html);
          } else if (res.status === 404) {
            setPreviewErrorMessage('Order not found');
          } else {
            throw new Error('Something went wrong on getting contract');
          }
        });
        await getContract(orderNo, 'pdf').then(async (res) => {
          if (res.ok) {
            const blob = await res.blob();
            const url = URL.createObjectURL(blob);
            setPdfContent(url);
          } else {
            throw new Error('Something went wrong on getting pdf contract');
          }
        });
        setIsPreviewLoading(false);
      } catch {
        console.error('Something went wrong on getting contract');
        setPreviewErrorMessage('Something went wrong on getting contract');
        setIsPreviewLoading(false);
      }
    };

    const handleAddToContract = async () => {
      setIsPreviewLoading(true);
      setPreviewErrorMessage('');

      const _notes = `${
        isFinanceApproval ? 'Subject to Finance approval. ' : ''
      }${isOther ? notes?.trim() : ''}`;

      setValue('notes', isOther ? notes?.trim() : '');

      try {
        const result = await postContractNotes(orderNo, _notes);
        if (result.ok) {
          await previewContract();
        } else if (result.status === 404) {
          setPreviewErrorMessage('Order not found');
        } else {
          throw new Error('Something went wrong on adding contract notes');
        }
      } catch {
        console.error('Something went wrong on adding contract notes');
        setPreviewErrorMessage('Something went wrong on adding contract notes');
        setIsPreviewLoading(false);
      }
    };

    return (
      <div className={styles.OrderContract}>
        {isSubmitted && <OverlayLoader />}
        <OrderBanner title="3. Approve Contract" withReturnUrl />
        <div className={styles.OrderContractContent} ref={parentRef}>
          <div className={styles.OrderContractForm}>
            <h2>Almost there!</h2>
            <p>
              This is it. If you would like to add any requested special
              conditions, please do so in the field below. We will let you know
              if we can accept your special conditions.
            </p>
            <Controller
              name="isFinanceApproval"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} className={styles.CheckBox}>
                  Subject to Finance approval
                </Checkbox>
              )}
            />
            <Controller
              name="isOther"
              control={control}
              render={({ field }) => (
                <Checkbox {...field} className={styles.CheckBox}>
                  Other
                </Checkbox>
              )}
            />
            {isOther && (
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <TextArea
                    {...field}
                    value={field.value || ''}
                    error={formState.errors.notes?.message}
                  />
                )}
              />
            )}
            <div className={styles.AddToContract}>
              <Button
                type="primary"
                buttonSize="medium"
                disabled={isPreviewLoading}
                onClick={async () => await handleAddToContract()}
              >
                Add to contract
              </Button>
            </div>
            <div className={styles.Preview}>
              {isPreviewLoading ? (
                <>
                  <p className="text-center">Loading...</p>
                  <Loading height="100%" />
                </>
              ) : (
                <>
                  {previewErrorMessage ? (
                    <div className={styles.ErrorMessage}>{errorMessage}</div>
                  ) : (
                    <>
                      {contentType?.includes('application/pdf') ? (
                        <object
                          data={content}
                          type="application/pdf"
                          width="100%"
                          height="600px"
                        >
                          <embed src={content} type="application/pdf" />
                          <p>
                            Your browser does not support PDFs. Download the PDF
                            using the link below.
                          </p>
                        </object>
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: content || ('' as string),
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
            {!isPreviewLoading && content && (
              <div className={styles.Download}>
                <a
                  href={pdfContent}
                  rel="noreferrer"
                  download={`Contract-${orderNo}.pdf`}
                >
                  <SvgIcon
                    type="download"
                    size={1.25}
                    color={`#${colorPrimary}`}
                  />
                  <span className={styles.DownloadText}>
                    Please download, print and save a copy of this document
                  </span>
                </a>
              </div>
            )}
            {isLoading ? (
              <Loading height="100%" />
            ) : (
              <>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <h3>Declaration</h3>
                  <div>
                    <Controller
                      name="contractAccepted"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          {...field}
                          error={formState.errors.contractAccepted?.message}
                        >
                          I have read, understood and agree to the Contract of
                          Sale. I understand that the Contract of Sale becomes a
                          legally binding contract upon its acceptance by&nbsp;
                          {byoState.seller?.name || 'Subaru (Aust) Pty Limited'}
                          . By placing the order you represent and warrant to
                          Subaru that the person/company named as the purchaser
                          on the order form is the final end retail purchaser of
                          the vehicle and that the purchaser is not purchasing
                          the vehicle for the purposes of resale.
                        </Checkbox>
                      )}
                    />
                  </div>
                  {errorMessage && (
                    <div className={styles.ErrorMessage}>{errorMessage}</div>
                  )}
                  <div className={styles.SubmitGroup}>
                    <Button
                      nativeType="submit"
                      type="primary"
                      buttonSize="medium"
                      disabled={isSubmitted}
                    >
                      Accept Terms & Submit
                    </Button>
                  </div>
                </form>
              </>
            )}
          </div>
          <div className={styles.OrderSummaryContainer}>
            <StickyChild
              ref={stickyRef}
              className={cn(
                styles.OrderSummarySticky,
                styles[`OrderSummarySticky${isSticky ? 'Top' : 'Bottom'}`]
              )}
            >
              <OrderSummary />
            </StickyChild>
          </div>
        </div>
      </div>
    );
  }
);
