import React, { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import cn from 'classnames';
import { BuildYourOwnState, OrderDetailsState } from '../../../store';
import { useRecoilValue } from 'recoil';
import { Button, Loading } from '~/common/components/ui-elements';
import { OrderDetails } from '~/common/models';

export const ProfileAccount = React.memo(() => {
  const { search, pathname } = useLocation();
  const byoState = useRecoilValue(BuildYourOwnState);
  const orderDetails = useRecoilValue(OrderDetailsState);
  const returnUrl = encodeURI(
    `/${byoState.baseUrlSegment}${pathname}${search}`
  );
  const { accountDetails } = orderDetails || ({} as OrderDetails);

  const address = useMemo(
    () =>
      accountDetails?.addresses && accountDetails?.addresses.length > 0
        ? accountDetails?.addresses[0]
        : null,
    [accountDetails?.addresses]
  );

  useEffect(() => {
    window['pushDigitalData']({
      event: '_pageLoaded',
    });
  }, []);

  return (
    <div className={styles.ProfileAccount}>
      {!orderDetails ? (
        <Loading height="100%" />
      ) : (
        accountDetails && (
          <>
            <h2>My Account</h2>
            <h3>Personal Details</h3>
            <div className={styles.Row}>
              <p>
                <strong>First Name</strong>
              </p>
              <p>{accountDetails.firstName}</p>
            </div>
            <div className={styles.Row}>
              <p>
                <strong>Last Name</strong>
              </p>
              <p>{accountDetails.lastName}</p>
            </div>
            <div className={styles.Row}>
              <p>
                <strong>Mobile Phone</strong>
              </p>
              <p>{accountDetails.phoneNo}</p>
            </div>
            <div className={styles.Row}>
              <p>
                <strong>Email</strong>
              </p>
              <p>{accountDetails.email}</p>
            </div>
            <div className={styles.Row}>
              <p>
                <strong>Street Address</strong>
              </p>
              <p>
                {address && (
                  <>
                    <span>{address?.address1}</span>
                    <br />
                    <span>{address?.address2}</span>
                    <br />
                    <span>{`${address?.city} ${address?.stateCode} ${address?.postalCode}`}</span>
                    <br />
                  </>
                )}
              </p>
            </div>
            <hr />
            <h3>Password</h3>
            <Button
              href={`/build-your-own/account/password?user=${accountDetails.email}&returnUrl=${returnUrl}`}
            >
              Change my password
            </Button>
          </>
        )
      )}
    </div>
  );
});
