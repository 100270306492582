import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import cn from 'classnames';
import {
  BuildYourOwnState,
  CarBuildState,
  useRefreshAuthenticationDetails,
} from '../../store';
import { OrderBanner } from '../order-banner';
import { OrderSummary } from '../order-summary';
import { useRecoilState } from 'recoil';
import { OrderAccountForm } from './order-account-form';
import { getCarBuild } from '~/common/services/build-your-own-service';
import queryString from 'query-string';
import { StickyChild } from '../..';

export const OrderDetails = React.memo(() => {
  const [byoState, setByoState] = useRecoilState(BuildYourOwnState);
  const refreshAuthenticationDetails = useRefreshAuthenticationDetails();
  const [_, setCarBuild] = useRecoilState(CarBuildState);
  const { search } = useLocation();
  const searchParams = queryString.parse(search);
  const id = searchParams.id as string | undefined;

  const [isSticky, setIsSticky] = useState(true);
  const parentRef = useRef<HTMLDivElement | null>(null);
  const stickyRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      const parentElement = parentRef.current;
      const stickyElement = stickyRef.current;

      if (parentElement && stickyElement) {
        const parentRect = parentElement.getBoundingClientRect();
        const stickyRect = stickyElement.getBoundingClientRect();

        // Check if the visible part of the parent is less than the height of the sticky component
        const isVisiblePartLess =
          parentRect.bottom - byoState.offsetHeight < stickyRect.height;

        setIsSticky(!isVisiblePartLess);
      }
    };

    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const loadCarBuild = useCallback(async () => {
    if (id) {
      setCarBuild(await getCarBuild(id));
    }
  }, [id]);

  const onLoad = useCallback(async () => {
    if (id) {
      await refreshAuthenticationDetails().then((updatedByoState) => {
        loadCarBuild();
        setByoState({ ...updatedByoState, id });
      });
    }
  }, [refreshAuthenticationDetails, loadCarBuild]);

  useEffect(() => {
    onLoad();
    window['pushDigitalData']({
      event: '_pageLoaded',
    });
    window['pushDigitalData'](
      {
        event: '_formNavigate',
        form: {
          name: 'buy online',
          stage: 'your contact details',
        },
      },
      true
    );
  }, [onLoad]);

  return (
    <div className={styles.OrderDetails}>
      {byoState && <OrderBanner title="1. Create Account" withReturnUrl />}
      <div className={styles.OrderDetailsContent} ref={parentRef}>
        <div className={styles.OrderDetailsForm}>
          <div className={styles.OrderDetailsFormIntro}>
            <h2>Buy online? It&apos;s easy.</h2>
            <p>
              You can now order your brand new Subaru entirely online, in your
              own time. It&apos;s just another way we&apos;re helping
              Australians do more and get more out of life. Enjoy.
            </p>
            <p>In just four easy steps, you can complete your order.</p>
            <ol>
              <li>
                Create your account and choose your delivering Subaru Retailer.
                You can even nominate to have your new Subaru&nbsp;
                <a href="/buy-online" target="_blank">
                  delivered to your door
                </a>
                &nbsp;by any one of our participating Subaru Retailers
                <sup>1</sup>.
                <span className="tt">
                  Terms and conditions apply*.
                  <span className="tt__content">
                    Subaru Home Delivery is subject to a&#41; individual Subaru
                    authorised Retailer delivery terms and conditions and b&#41;
                    the availability of an accessible, safe and legal delivery
                    location such as a residential driveway. For details contact
                    your local participating Subaru Retailer. Home Delivery may
                    not be available in some locations eg. remote or difficult
                    to access locations. For details contact your local
                    participating Subaru Retailer. Subaru Home Delivery is not
                    available on public holidays. Whilst your Subaru Retailer
                    will use reasonable efforts to facilitate the date and/or
                    time requested by you, no guarantee is provided in this
                    regard. Your request for a Subaru Home Delivery via this
                    website is not guaranteed until your nominated retailer
                    confirms your booking. Please contact them directly if you
                    have not heard from them within 24 hours of making your
                    booking. By making a Subaru Home Delivery booking, you agree
                    to all of the above terms and conditions.
                  </span>
                </span>
              </li>
              <li>
                Pay your refundable order fee. Minimum amount is $1000. We
                accept Visa, AMEX, and Mastercard.
              </li>
              <li>
                Review and approve your contract, plus add any special requests.
                For those opting for home delivery simply include this request
                within the additional comments field when placing your order.
              </li>
              <li>
                We will then be in touch to advise whether we have accepted your
                contract of sale and answer any questions.
              </li>
            </ol>
            <p>
              If we accept your order, you&apos;ll then be able to log into your
              account any time and track the progress of your order, download
              your contract and more.
            </p>
            <small>
              <i>
                <sup>1</sup>Home delivery not available at: Subaru Dubbo, Geoff
                King Subaru, Clarence Coast Subaru, Suttons Subaru - Rosebery,
                Hill Fitzsimmons, Cowra Subaru, Bundaberg Subaru, Gympie Subaru,
                Reef City Subaru, Mike Raleigh Subaru, Eblen Subaru, Albany
                Subaru, Bunbury Subaru, Subaru Osborne Park.
              </i>
            </small>
          </div>
          <OrderAccountForm />
        </div>
        <div className={styles.OrderSummaryContainer}>
          <StickyChild
            ref={stickyRef}
            className={cn(
              styles.OrderSummarySticky,
              styles[`OrderSummarySticky${isSticky ? 'Top' : 'Bottom'}`]
            )}
          >
            <OrderSummary />
          </StickyChild>
        </div>
      </div>
    </div>
  );
});
