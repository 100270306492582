import React, { useEffect } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { BuildYourOwnState, OrderDetailsState } from '../../../store';
import { useRecoilValue } from 'recoil';
import { Button, Loading } from '~/common/components/ui-elements';
import { OrderDetails, OrderDisplayStatus } from '~/common/models';
import { money } from '~/common/utils';

export const ProfileOrder = React.memo(() => {
  const byoState = useRecoilValue(BuildYourOwnState);
  const orderDetails = useRecoilValue(OrderDetailsState);
  const { carDetails, dealerDetails, financeDetails } = orderDetails
    ? orderDetails
    : ({} as OrderDetails);

  useEffect(() => {
    window['pushDigitalData']({
      event: '_pageLoaded',
    });
  }, []);

  const renderItem = (
    label: string,
    amount?: number,
    className = '',
    key?: string | number
  ) => (
    <p key={key} className={cn(styles.Item, className)}>
      <span>{label}</span>
      {amount !== undefined && <strong>{money(amount)}</strong>}
    </p>
  );

  const renderCarDetails = () => (
    <div className={cn(styles.Card, styles.CarDetails)}>
      <h5>{carDetails.title}</h5>
      <div className={styles.CardDetails}>
        {carDetails ? (
          <>
            <p>{carDetails.options.join(' | ')}</p>
            <p>Order Number: {orderDetails?.orderNo}</p>
            <p>VIN: {carDetails.vin}</p>
          </>
        ) : (
          <p>No car details</p>
        )}
      </div>
    </div>
  );

  const renderDealerDetails = () => (
    <div className={cn(styles.Card, styles.DelearDetails)}>
      <h5>Delivery Details</h5>
      <div className={styles.CardDetails}>
        {dealerDetails ? (
          <>
            <p>
              {dealerDetails.displayName}
              <br />
              LCMT: {dealerDetails.lmct}
            </p>
            <p>{dealerDetails.phoneNumber}</p>
            <p>
              {dealerDetails.address.address1}
              <br />
              {dealerDetails.address.address2 && (
                <>
                  {dealerDetails.address.address2}
                  <br />
                </>
              )}
              {`${dealerDetails.address.city} ${dealerDetails.address.stateCode} ${dealerDetails.address.postalCode}`}
            </p>
            {dealerDetails.websiteUrl && (
              <p>
                <a
                  href={dealerDetails.websiteUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  {dealerDetails.websiteName}
                </a>
              </p>
            )}
          </>
        ) : (
          <p>No delivery details</p>
        )}
      </div>
    </div>
  );

  const renderFinanceDetails = () => (
    <div className={cn(styles.Card, styles.FinanceDetails)}>
      <h5>Order Details</h5>
      <div className={styles.CardDetails}>
        {financeDetails ? (
          <>
            <div className={styles.OrderItem}>
              {renderItem(
                'Manufacturer List Price',
                financeDetails.manufacturerListPrice
              )}
              {renderItem('Dealer Delivery', financeDetails.dealerDelivery)}
              {renderItem('Registration', financeDetails.registration)}
              {financeDetails.hasCtp &&
                renderItem('Compulsory Third Party', financeDetails.ctp)}
              {financeDetails.hasTac &&
                renderItem('Traffic Accident Commission', financeDetails.tac)}
              {financeDetails.hasMaib &&
                renderItem(
                  'Motor Accident Insurance Board',
                  financeDetails.maib
                )}
              {renderItem('Stamp Duty', financeDetails.stampDuty)}
            </div>
            {financeDetails.hasAccessories && (
              <div className={styles.OrderItem}>
                {renderItem('Accessories', financeDetails.accessoriesTotal)}
                {financeDetails.accessories.map((acc, idx) =>
                  renderItem(acc.name, acc.price, styles.ItemBreakdown, idx)
                )}
                {financeDetails.accessoryOnRoadCosts &&
                  renderItem(
                    financeDetails.accessoryOnRoadCosts.name,
                    financeDetails.accessoryOnRoadCosts.price
                  )}
              </div>
            )}
            {financeDetails.hasExteriorOptionPriceEffect && (
              <div className={styles.OrderItem}>
                {renderItem('Colour', financeDetails.exteriorOption.price)}
                {renderItem(
                  financeDetails.exteriorOption.name,
                  undefined,
                  styles.ItemBreakdown
                )}
                {financeDetails.exteriorOptionOnRoadCosts &&
                  renderItem(
                    financeDetails.exteriorOptionOnRoadCosts.name,
                    financeDetails.exteriorOptionOnRoadCosts.price
                  )}
              </div>
            )}
            {financeDetails.hasInteriorOptionPriceEffect && (
              <div className={styles.OrderItem}>
                {renderItem('Interior', financeDetails.interiorOption.price)}
                {renderItem(
                  financeDetails.interiorOption.name,
                  undefined,
                  styles.ItemBreakdown
                )}
                {financeDetails.interiorOptionOnRoadCosts &&
                  renderItem(
                    financeDetails.interiorOptionOnRoadCosts.name,
                    financeDetails.interiorOptionOnRoadCosts.price
                  )}
              </div>
            )}
            {financeDetails.hasLuxuryCarTax && (
              <div className={styles.OrderItem}>
                {renderItem('Luxury Car Tax', financeDetails.luxuryCarTax)}
              </div>
            )}
            {(financeDetails.hasCappedService ||
              financeDetails.hasExtendedWarranty) && (
              <div className={styles.OrderItem}>
                {financeDetails.hasCappedService &&
                  renderItem(
                    'Service Plan',
                    financeDetails.cappedService.price
                  )}
                {financeDetails.hasExtendedWarranty &&
                  renderItem(
                    financeDetails.extendedWarranty.name,
                    financeDetails.extendedWarranty.price
                  )}
              </div>
            )}
            <div className={cn(styles.OrderItem, styles.Total)}>
              {renderItem('Order Total', financeDetails.finalDap)}
            </div>
            <div className={styles.OrderItem}>
              {renderItem('Deposit', financeDetails.deposit)}
              {renderItem(
                'Additional Payment',
                financeDetails.additionalPayment
              )}
            </div>
            <div className={cn(styles.OrderItem, styles.Total)}>
              {renderItem(
                'Total Amount Owing',
                financeDetails.totalAmountOwing
              )}
            </div>
          </>
        ) : (
          <p>No order details</p>
        )}
      </div>
    </div>
  );

  return (
    <div className={styles.ProfileOrder}>
      {!orderDetails ? (
        <Loading height="100%" />
      ) : (
        <div className="grid grid-cols-12 gap-10">
          {orderDetails?.widget.orderDisplayStatus ===
          OrderDisplayStatus.DepositRequired ? (
            <div className="col-span-12">
              <h2>You&apos;re almost there</h2>
              <p>
                To complete your order, all you need to do is pay a deposit.
              </p>
              <div className={styles.CTA}>
                <Button
                  type="primary"
                  buttonSize="medium"
                  href={`/build-your-own/order/${orderDetails.orderNo}/pay`}
                >
                  Pay Deposit Now
                </Button>
              </div>
            </div>
          ) : orderDetails?.widget.orderDisplayStatus ===
            OrderDisplayStatus.ContractPending ? (
            <div className="col-span-12">
              <h2>You&apos;re almost there</h2>
              <p>
                Please review and confirm your agreement to the contract terms
                so we can review your order. NB Your order constitutes an offer
                to purchase. No binding agreement arises between us until such
                time as we confirm acceptance of your order.
              </p>
              <div className={styles.CTA}>
                <Button
                  type="primary"
                  buttonSize="medium"
                  href={`/build-your-own/order/${orderDetails.orderNo}/contract`}
                >
                  Review &amp; Confirm contract
                </Button>
              </div>
            </div>
          ) : (
            <>
              <div className={`col-span-${byoState.isMobile ? 12 : 6}`}>
                <h2>Order Summary</h2>
                {renderCarDetails()}
                {renderDealerDetails()}
              </div>
              <div className={`col-span-${byoState.isMobile ? 12 : 6}`}>
                {renderFinanceDetails()}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
});
