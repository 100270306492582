import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { z, emailAddressSchema } from '~/common/utils/validations';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, Input } from '~/common/components/ui-elements';
import { postRecover } from '~/common/services/build-your-own-service';
import { useHistory } from 'react-router-dom';
import { OverlayLoader } from '../..';

type Nullish = string | undefined | null;
interface RecoverInfo {
  email: Nullish;
}

const defaultRecoverInfo: RecoverInfo = {
  email: '',
};

const RecoverSchema = z.object({
  email: z
    .string()
    .transform((val) => (typeof val === 'string' ? val.trim() : val))
    .nullish()
    .refine((value) => value && value.length > 0, {
      message: 'Email address is required',
    })
    .refine((value) => emailAddressSchema.safeParse(value).success),
});

type FormSchema = z.infer<typeof RecoverSchema>;

export const AccountRecover = React.memo(() => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const { control, handleSubmit, formState } = useForm<FormSchema>({
    resolver: zodResolver(RecoverSchema),
    defaultValues: defaultRecoverInfo,
    mode: 'all',
  });

  useEffect(() => {
    window['pushDigitalData']({
      event: '_pageLoaded',
    });
  }, []);

  const handleFormSubmit = async (data: FormSchema) => {
    try {
      setErrorMessage('');
      setIsSubmitted(true);

      const result = await postRecover(data);
      if (result.success) {
        history.push('/account/login');
      } else {
        setErrorMessage(result.message || 'Something went wrong');
        setIsSubmitted(false);
      }
    } catch {
      setErrorMessage('Something went wrong');
      console.error('Something went wrong on reset');
      setIsSubmitted(false);
    }
  };

  return (
    <div className={styles.AccountRecover}>
      {isSubmitted && <OverlayLoader />}
      <div className={styles.Container}>
        <div className={styles.FormContainer}>
          <h2>Reset Password</h2>
          <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className="grid grid-cols-12 gap-4">
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    autoComplete="off"
                    label="Email address"
                    variant="outlined"
                    className="col-span-12"
                    value={field.value || ''}
                    error={formState.errors?.email?.message}
                  />
                )}
              />
              <p className="col-span-12">
                <strong>Note:</strong> Your current password will be reset and
                password recovery instructions will be sent to your email.
              </p>
            </div>
            {errorMessage && (
              <div className={styles.ErrorMessage}>{errorMessage}</div>
            )}
            <div className={styles.SubmitGroup}>
              <Button
                nativeType="submit"
                type="primary"
                buttonSize="medium"
                disabled={isSubmitted}
              >
                Reset
              </Button>
              <Button type="text" href="/build-your-own/account/login">
                Login
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});
