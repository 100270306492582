import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styles from './index.module.scss';
import { BuildYourOwnState } from '../../store';
import { OrderBanner } from '../order-banner';
import { useRecoilState } from 'recoil';
import { Button, Loading } from '~/common/components/ui-elements';
import { getDealerDetails } from '~/common/services/build-your-own-service';
import { ContactMethodType } from '~/common/models';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

interface MatchParams {
  orderNo: string;
}

export const OrderThankYou = React.memo(
  (props: RouteComponentProps<MatchParams>) => {
    const [byoState, setByoState] = useRecoilState(BuildYourOwnState);
    const { orderNo } = props.match.params;
    const [isLoading, setIsLoading] = useState(true);

    const onLoad = useCallback(async () => {
      if (orderNo) {
        if (!byoState.dealer) {
          const dealer = await getDealerDetails(orderNo, true);
          setByoState((prevState) => ({ ...prevState, dealer }));
        }
        if (byoState.dealer) {
          setIsLoading(false);
        }
      }
    }, [orderNo, byoState.dealer]);

    useEffect(() => {
      onLoad();
      window['pushDigitalData']({
        event: '_pageLoaded',
      });
      if (
        document.referrer.includes(`/build-your-own/order/${orderNo}/contract`)
      ) {
        window['pushDigitalData'](
          {
            event: '_formNavigate',
            form: {
              name: 'buy online',
              stage: 'submitted',
            },
          },
          true
        );
      }
    }, [onLoad]);

    const renderDealerSchedule = useCallback(() => {
      if (byoState.dealer) {
        const schedule = byoState.dealer?.details
          .split(/[\|\n]/)
          .filter((entry) => entry.trim() !== '');

        return schedule.length > 0 ? (
          <p>
            {schedule.map((sched, idx) => (
              <>
                <span key={idx}>{sched}</span>
                <br />
              </>
            ))}
          </p>
        ) : (
          <></>
        );
      }
      return <></>;
    }, [byoState.dealer]);

    const renderDealerPhone = useCallback(() => {
      let result = '';
      if (byoState.dealer) {
        result = byoState.dealer?.contacts
          .filter((contact) => contact.isDefault)
          .flatMap((contact) => contact.contactMethods)
          .filter(
            (method) =>
              method.isActive && method.type === ContactMethodType.Phone
          )
          .map((method) => method.value)
          .join(' / ');
      }
      return result ? <p>{result}</p> : <></>;
    }, [byoState.dealer]);

    const getDealerAddress = () => {
      return byoState.dealer?.addresses.find(
        (addr) => addr.isActive && addr.isDefault
      );
    };

    const renderDealerAddress = useCallback(() => {
      if (byoState.dealer) {
        const address = getDealerAddress();
        return address ? (
          <p>
            <span>{address?.address1}</span>
            <br />
            {address?.address2 && (
              <>
                <span>{address?.address2}</span>
                <br />
              </>
            )}
            <span>{`${address?.city} ${address?.stateCode}`}</span>
            <br />
          </p>
        ) : (
          <></>
        );
      }
      return <></>;
    }, [byoState.dealer]);

    return (
      <div className={styles.OrderThankYou}>
        <OrderBanner title="4. All Done" withReturnUrl />
        <div className={styles.OrderThankYouContent}>
          <h2>Thank you!</h2>
          <p>
            We will be in touch shortly to advise whether we have accepted the
            Contract of Sale and answer any questions.
          </p>

          <div className={styles.GoToAccount}>
            <Button
              type="primary"
              buttonSize="medium"
              href={`/${byoState.baseUrlSegment}/profile?orderNo=${orderNo}`}
            >
              Go to my account
            </Button>
          </div>
          <hr />
          {isLoading ? (
            <Loading height="100%" />
          ) : (
            <>
              <h3>Say hello to your delivering Subaru Retailer</h3>
              {byoState.dealer?.isMobileDealer ? (
                <p>
                  You&apos;ve chosen <strong>{byoState.dealer?.name}</strong> as
                  your preferred retailer for your new Subaru. We will be in
                  touch to arrange delivery.
                </p>
              ) : (
                <p>
                  You&apos;ve chosen <strong>{byoState.dealer?.name}</strong> as
                  your preferred delivering retailer for your new Subaru. Their
                  details are below if you&apos;d like to get in touch before
                  your vehicle arrives.
                </p>
              )}
              <h3>{byoState.dealer?.name}</h3>
              {renderDealerSchedule()}
              {renderDealerPhone()}
              {renderDealerAddress()}
              {!byoState.dealer?.isMobileDealer && getDealerAddress() && (
                <div>
                  {getDealerAddress() ? (
                    <LoadScript googleMapsApiKey={byoState.googleMapsApiKey}>
                      <GoogleMap
                        mapContainerClassName={styles.DealerMap}
                        center={{
                          lat: getDealerAddress()?.geoLatitude || 0,
                          lng: getDealerAddress()?.geoLongitude || 0,
                        }}
                        zoom={15}
                      >
                        <Marker
                          position={{
                            lat: getDealerAddress()?.geoLatitude || 0,
                            lng: getDealerAddress()?.geoLongitude || 0,
                          }}
                        />
                      </GoogleMap>
                    </LoadScript>
                  ) : (
                    <>loading...</>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
);
