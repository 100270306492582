import React, { useEffect } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { OrderDetailsState } from '../../../store';
import { useRecoilValue } from 'recoil';
import { Loading } from '~/common/components/ui-elements';

export const ProfilePayBalance = React.memo(() => {
  const orderDetails = useRecoilValue(OrderDetailsState);

  useEffect(() => {
    window['pushDigitalData']({
      event: '_pageLoaded',
    });
  }, []);

  return (
    <div className={styles.ProfilePayBalance}>
      {!orderDetails ? (
        <Loading height="100%" />
      ) : (
        <>
          <h2>How to pay</h2>
          <p className={styles.Intro}>
            Ready to settle the remaining balance on your new Subaru? It&apos;s
            easy. Just use one of the three options detailed below.
          </p>
          <p className={styles.Intro}>
            Please remember that the remaining balance must be paid with cleared
            funds 48 hours prior to dispatch to your chosen Subaru Retailer.
          </p>
          <div className={styles.Card}>
            <h5>Electronic Funds Transfer &#40;EFT&#41;</h5>
            <div className={styles.CardDetails}>
              <p>
                You can pay your remaining balance via EFT into the bank account
                below.
              </p>
              <div className={styles.Row}>
                <p>
                  <strong>Account Name</strong>
                </p>
                <p>Subaru &#40;Aust.&#41; Pty Limited</p>
              </div>
              <div className={styles.Row}>
                <p>
                  <strong>BSB Number</strong>
                </p>
                <p>032-078</p>
              </div>
              <div className={styles.Row}>
                <p>
                  <strong>Account Number</strong>
                </p>
                <p>778994</p>
              </div>
              <p>
                <i>
                  Please use your Order Number as your reference -&nbsp;
                  <strong>{orderDetails.orderNo}</strong>
                </i>
              </p>
            </div>
          </div>
          <div className={styles.Card}>
            <h5>Telegraphic Transfer &#40;TT&#41;</h5>
            <div className={styles.CardDetails}>
              <p>
                You can pay your remaining balance via TT using the details
                below.
              </p>
              <div className={styles.Row}>
                <p>
                  <strong>Beneficiary Name</strong>
                </p>
                <p>Subaru &#40;Aust.&#41; Pty Limited</p>
              </div>
              <div className={styles.Row}>
                <p>
                  <strong>Beneficiary Address</strong>
                </p>
                <p>Level 3, 4 Burbank Place, Norwest, NSW 2153</p>
              </div>
              <div className={styles.Row}>
                <p>
                  <strong>Country of Beneficiary</strong>
                </p>
                <p>Australia</p>
              </div>
              <div className={styles.Row}>
                <p>
                  <strong>Beneficiary BSB</strong>
                </p>
                <p>032-078</p>
              </div>
              <div className={styles.Row}>
                <p>
                  <strong>Account Number</strong>
                </p>
                <p>778994</p>
              </div>
              <div className={styles.Row}>
                <p>
                  <strong>Beneficiary Bank SWIFT/BIC Code</strong>
                </p>
                <p>WPACAU2S</p>
              </div>
              <div className={styles.Row}>
                <p>
                  <strong>Beneficiary Bank Name</strong>
                </p>
                <p>Westpac Banking Corporation</p>
              </div>
              <div className={styles.Row}>
                <p>
                  <strong>Beneficiary Bank Address</strong>
                </p>
                <p>Cnr Church &amp; George Streets, Parramatta, NSW 2150</p>
              </div>
              <div className={styles.Row}>
                <p>
                  <strong>
                    Country in which the Beneficiary’s bank is located
                  </strong>
                </p>
                <p>Australia</p>
              </div>
              <p>
                <i>
                  Please include your contact details and your Order Number
                  -&nbsp;
                  <strong>{orderDetails.orderNo}</strong>
                </i>
              </p>
            </div>
          </div>
          <div className={styles.Card}>
            <h5>Bank Cheque</h5>
            <div className={styles.CardDetails}>
              <p>
                You can pay your remaining balance with a bank cheque using the
                details below.
              </p>
              <div className={styles.Row}>
                <p>
                  <strong>Payable to</strong>
                </p>
                <p>Subaru &#40;Aust.&#41; Pty Limited</p>
              </div>
              <div className={styles.Row}>
                <p>
                  <strong>Attention to</strong>
                </p>
                <p>Buyonline Coordinator</p>
              </div>
              <div className={styles.Row}>
                <p>
                  <strong>Address</strong>
                </p>
                <p>Level 3, 4 Burbank Place, Norwest, NSW 2153</p>
              </div>
              <p>
                <i>
                  Please enclose a note stating your contact details and your
                  Order Number -&nbsp;
                  <strong>{orderDetails.orderNo}</strong>
                </i>
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
});
