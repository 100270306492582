import React, { useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import {
  BuildYourOwnState,
  OrderDetailsState,
  useRefreshAuthenticationDetails,
} from '../../store';
import { useRecoilState } from 'recoil';
import { ProfileBanner } from '../profile-banner';
import {
  Route,
  RouteComponentProps,
  useHistory,
  useLocation,
} from 'react-router-dom';
import queryString from 'query-string';
import { ProfileOrder } from './profile-order';
import { ProfilePayBalance } from './profile-pay-balance';
import { ProfileDocuments } from './profile-documents';
import { ProfileAccount } from './profile-account';
import {
  getOrderDetails,
  getProfileOrders,
} from '~/common/services/build-your-own-service';
import { Loading } from '~/common/components/ui-elements';
import { isOrderNo } from '../..';
import {
  FinanceStatusDescription,
  OrderDetails,
  OrderDisplayStatusDescription,
} from '~/common/models';

export interface ProfileNavItem {
  label: string;
  path?: string;
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  subItems?: string[];
}

export const Profile = React.memo(() => {
  const [byoState] = useRecoilState(BuildYourOwnState);
  const refreshAuthenticationDetails = useRefreshAuthenticationDetails();
  const [orderDetails, setOrderDetails] = useRecoilState(OrderDetailsState);
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = queryString.parse(search);
  const orderNo = searchParams.orderNo as string | undefined;
  const [currentOrderNo, setCurrentOrderNo] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(true);
  const [navItems, setNavItems] = useState<ProfileNavItem[]>([
    {
      label: 'My Order',
      path: '/profile',
      component: ProfileOrder,
      subItems: [],
    },
    {
      label: 'Pay Balance',
      path: '/profile/pay-balance',
      component: ProfilePayBalance,
    },
    {
      label: 'Documents',
      path: '/profile/documents',
      component: ProfileDocuments,
    },
    {
      label: 'My Account',
      path: '/profile/my-account',
      component: ProfileAccount,
    },
  ]);

  const onLoad = useCallback(async () => {
    await refreshAuthenticationDetails();
    const orders = await getProfileOrders();
    if (orders && orders.length > 0) {
      setNavItems((prevNavItems) => {
        const updatedNavItems = [...prevNavItems];
        updatedNavItems[0] = {
          ...updatedNavItems[0],
          subItems: orders,
        };
        return updatedNavItems;
      });

      if (!orders.some((x) => x === orderNo)) {
        setCurrentOrderNo(orders[0]);
      } else {
        setCurrentOrderNo(orderNo && isOrderNo(orderNo) ? orderNo : orders[0]);
      }
    } else {
      setCurrentOrderNo(undefined);
    }
  }, []);

  const loadOrder = useCallback(async () => {
    if (currentOrderNo) {
      setIsLoading(true);
      const data = await getOrderDetails(currentOrderNo)
        .then((res) => res.json())
        .then((res) => res as OrderDetails);
      setOrderDetails(data);
    }
  }, [currentOrderNo]);

  useEffect(() => {
    loadOrder();
  }, [loadOrder]);

  useEffect(() => {
    if (orderDetails) {
      setIsLoading(false);
    }
  }, [orderDetails]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const handleNavClick = (path?: string, orderNumber?: string) => {
    const _orderNo = orderNumber ?? currentOrderNo;
    setCurrentOrderNo(_orderNo);
    if (path) history.push(`${path}${_orderNo ? `?orderNo=${_orderNo}` : ''}`);
  };

  return (
    <div className={styles.Profile}>
      {byoState && (
        <ProfileBanner
          {...{
            navItems,
            handleNavClick,
            currentOrderNo,
          }}
        />
      )}
      {isLoading ? (
        <Loading height="100%" />
      ) : (
        <div className={cn('grid grid-cols-12 gap-10', styles.Container)}>
          {!currentOrderNo && !orderDetails ? (
            <div className={cn('col-span-12', styles.OrderNotFound)}>
              No orders found.
            </div>
          ) : (
            <>
              <div className={`col-span-${byoState.isMobile ? 12 : 4}`}>
                <div className={cn(styles.Widget)}>
                  {orderDetails && (
                    <>
                      <img src={orderDetails?.widget.carImageFullUrl} />
                      <p>Order Status</p>
                      <div className={styles.OrderStatus}>
                        {
                          OrderDisplayStatusDescription[
                            orderDetails?.widget.orderDisplayStatus
                          ]
                        }
                      </div>
                      {orderDetails.widget.showFinanceStatus && (
                        <>
                          <p>Finance Application</p>
                          <div
                            className={cn(
                              styles.FinanceApplicationStatus,
                              styles[
                                `styles.FinanceApplicationStatus${orderDetails?.widget.financeStatus}`
                              ]
                            )}
                          >
                            {
                              FinanceStatusDescription[
                                orderDetails?.widget.financeStatus
                              ]
                            }
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div
                className={cn(
                  `col-span-${byoState.isMobile ? 12 : 8}`,
                  styles.Content
                )}
              >
                {navItems.map((item, idx) => (
                  <Route
                    exact
                    key={`${idx}`}
                    path={item.path}
                    component={item.component}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      )}
      <div className={styles.Footer}>
        <hr />
        <p>
          If you have any questions regarding your order, please contact our Buy
          Online Coordinator via&nbsp;
          <a
            href={`mailto:buyonline@subaru.com.au?subject=Enquiry%20regarding%20order#${currentOrderNo}`}
          >
            email
          </a>
          .
        </p>
      </div>
    </div>
  );
});
