import React, { useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';
import cn from 'classnames';
import { BuildYourOwnState, OrderDetailsState } from '../../store';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  getOrderDetails,
  getOrderDetailsByRef,
} from '~/common/services/build-your-own-service';
import { Loading, SvgIcon } from '~/common/components/ui-elements';
import { colorPrimary, money } from '~/common/utils';
import { OrderDisclaimerRDP } from '../order-disclaimer';
import { usePortals } from 'react-portal-hook';
import { useHistory, useLocation } from 'react-router-dom';
import { OrderDetails } from '~/common/models';

export const OrderSummary = React.memo(({}) => {
  const byoState = useRecoilValue(BuildYourOwnState);
  const { id, orderNo } = byoState;
  const [orderDetails, setOrderDetails] = useRecoilState(OrderDetailsState);
  const portalManager = usePortals();
  const [isLoading, setIsLoading] = useState(true);
  const { search, pathname } = useLocation();
  const history = useHistory();
  const returnUrl = encodeURI(
    `/${byoState.baseUrlSegment}${pathname}${search}`
  );
  const loginUrl = `/account/login?returnUrl=${returnUrl}`;

  const loadOrder = useCallback(async () => {
    setIsLoading(true);
    if (orderNo) {
      const data = await getOrderDetails(orderNo)
        .then((res) => {
          if (res.status === 403) {
            history.push(loginUrl);
          }
          return res.json();
        })
        .then((res) => res as OrderDetails);
      setOrderDetails(data);
    } else if (id) {
      const data = await getOrderDetailsByRef(id);
      setOrderDetails(data);
    }
  }, [id, orderNo]);

  useEffect(() => {
    if (orderDetails) {
      setIsLoading(false);
    }
  }, [orderDetails]);

  useEffect(() => {
    loadOrder();
  }, [loadOrder]);

  const showBasePriceDisclaimer = () => {
    portalManager.open((portal) => (
      <OrderDisclaimerRDP title="Base Price" closeModal={portal.close} />
    ));
  };

  const showRDPDisclaimer = () => {
    portalManager.open((portal) => (
      <OrderDisclaimerRDP
        title="Recommended Driveaway Price"
        closeModal={portal.close}
      />
    ));
  };

  const renderSubItem = (name: string) => {
    return (
      <div className={styles.NoIcon}>
        <small className={styles.Indent}>
          <span>{name}</span>
        </small>
      </div>
    );
  };

  const renderItemPrice = (name: string, price?: number) => {
    return (
      <div className={styles.NoIcon}>
        <small>
          <span>{name}</span>
          <span>
            <b>{money(price ?? 0, 0)}</b>
          </span>
        </small>
      </div>
    );
  };

  return (
    <div className={styles.OrderSummary}>
      {isLoading ? (
        <Loading height="100%" />
      ) : (
        <>
          <h6>Order Summary</h6>
          <p>
            <b>Vehicle:&nbsp;{orderDetails?.carDetails?.title}</b>
          </p>
          <small>
            {orderDetails?.carDetails?.options.filter(Boolean).join(' | ')}
            {orderDetails?.carDetails?.vin &&
              ` | VIN ${orderDetails?.carDetails?.vin}`}
          </small>
          <div>
            <hr />
          </div>
          <p className={styles.RDP}>
            <span>{money(orderDetails?.financeDetails?.finalDap ?? 0, 0)}</span>
            <span>
              &nbsp;RDP
              <sup>
                <i className="icon icon--info" onClick={showRDPDisclaimer} />
              </sup>
            </span>
          </p>
          <div>
            <hr />
          </div>
          <div className={styles.Price}>
            <div>
              <small>
                <span>Base Price</span>
                <span>
                  <b>{money(orderDetails?.financeDetails?.baseDap ?? 0, 0)}</b>
                  <sup>
                    <i
                      className="icon icon--info"
                      onClick={showBasePriceDisclaimer}
                    />
                  </sup>
                </span>
              </small>
            </div>
            {orderDetails?.financeDetails?.hasAccessories && (
              <>
                {renderItemPrice(
                  'Accessories',
                  orderDetails?.financeDetails?.accessoriesTotal
                )}
                <div className={cn(styles.NoIcon, styles.Breakdown)}>
                  {orderDetails?.financeDetails?.accessories.map((a, idx) => (
                    <small key={idx} className={styles.Indent}>
                      <span>{a.name}</span>
                      <span>{money(a.price ?? 0, 0)}</span>
                    </small>
                  ))}
                </div>
                {orderDetails?.financeDetails?.accessoryOnRoadCosts &&
                  renderItemPrice(
                    orderDetails?.financeDetails?.accessoryOnRoadCosts.name,
                    orderDetails?.financeDetails?.accessoryOnRoadCosts.price
                  )}
              </>
            )}
            {orderDetails?.financeDetails?.hasInteriorOptionPriceEffect && (
              <>
                {renderItemPrice(
                  'Interior',
                  orderDetails?.financeDetails?.interiorOption.price
                )}
                {renderSubItem(
                  orderDetails?.financeDetails?.interiorOption.name
                )}
                {orderDetails?.financeDetails?.interiorOptionOnRoadCosts &&
                  renderItemPrice(
                    orderDetails?.financeDetails?.interiorOptionOnRoadCosts
                      .name,
                    orderDetails?.financeDetails?.interiorOptionOnRoadCosts
                      .price
                  )}
              </>
            )}
            {orderDetails?.financeDetails?.hasExteriorOptionPriceEffect && (
              <>
                {renderItemPrice(
                  'Colour',
                  orderDetails?.financeDetails?.exteriorOption.price
                )}
                {renderSubItem(
                  orderDetails?.financeDetails?.exteriorOption.name
                )}
                {orderDetails?.financeDetails?.exteriorOptionOnRoadCosts &&
                  renderItemPrice(
                    orderDetails?.financeDetails?.exteriorOptionOnRoadCosts
                      .name,
                    orderDetails?.financeDetails?.exteriorOptionOnRoadCosts
                      .price
                  )}
              </>
            )}
            {orderDetails?.financeDetails?.hasCappedService &&
              renderItemPrice(
                'Service Plan',
                orderDetails?.financeDetails?.cappedService.price
              )}
            {orderDetails?.financeDetails?.hasExtendedWarranty &&
              renderItemPrice(
                orderDetails?.financeDetails?.extendedWarranty.name,
                orderDetails?.financeDetails?.extendedWarranty.price
              )}
          </div>
          <div>
            <hr />
          </div>
          <div className={styles.Ctas}>
            {orderDetails?.showGetFinanceQuote && (
              <a
                href={orderDetails?.financeUrl}
                target="_blank"
                rel="noreferrer"
              >
                <SvgIcon type="financeQuote" color={`#${colorPrimary}`} />
                <span>Finance Quote</span>
              </a>
            )}
            <a href="/value-my-car" target="_blank" rel="noreferrer">
              <SvgIcon type="tradeIn" color={`#${colorPrimary}`} />
              <span>Get Used Car Valuation</span>
            </a>
            {!orderDetails?.carDetails?.isPreOrder && (
              <a
                href={`/book-test-drive?model=${orderDetails?.carDetails?.modelCodeForTestDrive}`}
                target="_blank"
                rel="noreferrer"
              >
                <SvgIcon type="drive" color={`#${colorPrimary}`} />
                <span>Book Test Drive</span>
              </a>
            )}
          </div>
        </>
      )}
    </div>
  );
});
