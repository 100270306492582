import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import cn from 'classnames';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  BuildYourOwnState,
  CarBuildState,
  AccountDetailsState,
} from '~/pages/build-your-own/store';
import {
  RadioButton,
  FileUpload,
  Input,
  Dropdown,
  Checkbox,
  Button,
  SvgIcon,
  DatePickerWrapper,
  Loading,
} from '~/common/components/ui-elements';
import { Controller, useForm } from 'react-hook-form';
import { AccountDetails, Option } from '~/common/models/index';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  z,
  trueOnly,
  phoneNumberSchema,
  validateEmail,
  nameNullable,
  requiredNullable,
} from '~/common/utils/validations';
import { colorPrimary, createFormData } from '~/common/utils';
import { ZodIssueCode } from 'zod';
import {
  getAccountDetails,
  getSellerDetails,
  postAccountDetails,
} from '~/common/services/build-your-own-service';
import { Dealer } from '~/pages/find-dealer/models';
import { isOrderNo, OverlayLoader } from '~/pages/build-your-own';

const subtractYears = (date: Date, years: number) => {
  date.setFullYear(date.getFullYear() - years);

  return date;
};

const defaultAddress: AccountDetails['address'] = {
  id: 0,
  address1: '',
  address2: '',
  city: '',
  stateCode: '',
  postalCode: '',
  countryCode: '',
  geoLatitude: undefined,
  geoLongitude: undefined,
  userId: 0,
  isActive: true,
  isDefault: true,
};

const defaultAccount: AccountDetails['account'] = {
  fullName: '',
  isCompany: false,
  opInEmailMarketing: false,
  opInPostMarketing: false,
  opInPhoneAndSmsMarketing: false,
  id: 0,
  flags: 0,
  companyName: '',
  companyAbn: '',
  jobTitle: '',
  prefix: '',
  firstName: '',
  middleName: undefined,
  lastName: '',
  email: '',
  phoneNo: '',
  dateOfBirth: null, // subtractYears(new Date(), 18),
  isAuthenticated: false,
  emailConfirm: '',
  password: '',
  passwordConfirm: '',
  addresses: [defaultAddress],
  licence: null,
};

const defaultAccountDetails: AccountDetails = {
  orderReference: '',
  account: defaultAccount,
  address: defaultAddress,
  dealerCode: '',
  accept12MonthsRoadsideAssistance: true,
  agreePrivacyCollection: false,
};

const isAtLeast18YearsOld = (date: Date) => {
  const today = new Date();
  const age = today.getFullYear() - date.getFullYear();
  const month = today.getMonth() - date.getMonth();
  const day = today.getDate() - date.getDate();

  return age > 18 || (age === 18 && (month > 0 || (month === 0 && day >= 0)));
};

type Nullish = string | undefined | null;

// Address schema
const AddressSchema = z.object({
  id: z.number(),
  address1: requiredNullable('Street address is required', 250),
  address2: z
    .string()
    .nullish()
    .transform((val): Nullish => val ?? ''),
  city: z.string().nullish(),
  stateCode: z.string().nullish(),
  postalCode: z.string().nullish(),
  countryCode: z.string().nullish(),
  geoLatitude: z.number().nullish(),
  geoLongitude: z.number().nullish(),
  userId: z.number(),
});

// Account schema
const AccountSchema = z
  .object({
    fullName: z.string().nullish(),
    isCompany: z.boolean(),
    opInEmailMarketing: z.boolean(),
    opInPostMarketing: z.boolean(),
    opInPhoneAndSmsMarketing: z.boolean(),
    id: z.number(),
    flags: z.number(),
    companyName: z
      .string()
      .nullish()
      .transform((val): Nullish => val ?? ''),
    companyAbn: z
      .string()
      .nullish()
      .transform((val): Nullish => val ?? ''),
    jobTitle: z
      .string()
      .nullish()
      .transform((val): Nullish => val ?? ''),
    prefix: z
      .string()
      .nullish()
      .transform((val): Nullish => val ?? ''),
    firstName: nameNullable('First name is empty or invalid'),
    middleName: z
      .string()
      .nullish()
      .transform((val): Nullish => val ?? ''),
    lastName: nameNullable('Last name is empty or invalid'),
    email: z.string().nullish(),
    phoneNo: z
      .string()
      .transform((val) => (typeof val === 'string' ? val.trim() : val))
      .nullish()
      .refine((value) => value && value.length > 0, {
        message: 'Phone number is required',
      })
      .refine((value) => phoneNumberSchema.safeParse(value).success),
    dateOfBirth: z
      .date()
      .nullish()
      .refine((date) => date && !isNaN(date.getTime()), {
        message: 'Date of birth is required',
      })
      .refine(
        (value) => {
          if (value === null || value === undefined) return true;
          return value <= new Date();
        },
        {
          message: 'Date of birth cannot be in the future.',
        }
      ),
    // .refine((date) => date && isAtLeast18YearsOld(date), {
    //   message: 'You must be at least 18 years old',
    // }),
    isAuthenticated: z.boolean().nullish(),
    emailConfirm: z.string().nullish(),
    password: z.string().nullish(),
    passwordConfirm: z.string().nullish(),
    licence: z.any().nullish(),
  })
  .superRefine((data, ctx) => {
    if (!data.isCompany) {
      if (
        data.licence === null ||
        !(data.licence instanceof FileList) ||
        data.licence.length !== 1
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['licence'],
          message: 'Licence file is required',
        });
      }
    } else {
      if (!data.companyName || data.companyName === '') {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['companyName'],
          message: 'Company name is required',
        });
      }
      if (!data.companyAbn || data.companyAbn === '') {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['companyAbn'],
          message: 'Company ABN/ACN is required',
        });
      }
    }

    if (!data.isAuthenticated) {
      if (data.email && !validateEmail(data.email)) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['email'],
          message: 'Please enter a valid email address',
        });
      } else if (!data.email) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['email'],
          message: 'Email address is required',
        });
      }

      if (data.emailConfirm && !validateEmail(data.emailConfirm)) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['emailConfirm'],
          message: 'Please enter a valid email address',
        });
      } else if (
        data.email &&
        data.email?.toLowerCase() !== data.emailConfirm?.toLowerCase()
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['emailConfirm'],
          message: 'Confirm your email address',
        });
      }

      if (!data.password) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['password'],
          message: 'Password is required',
        });
      }
      if (data.password && data.password !== data.passwordConfirm) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['passwordConfirm'],
          message: 'Confirm your password',
        });
      }
    }
  });

const AccountDetailsSchema = z.object({
  orderReference: z
    .string()
    .nullish()
    .transform((val): Nullish => val ?? ''),
  account: AccountSchema,
  address: AddressSchema,
  dealerCode: requiredNullable('Dealer is required'),
  accept12MonthsRoadsideAssistance: z.boolean().nullish(),
  agreePrivacyCollection: trueOnly(
    'You must agree to the Privacy Collection Statement'
  ),
});

type FormSchema = z.infer<typeof AccountDetailsSchema>;

export const OrderAccountForm = React.memo(() => {
  const [byoState, setByoState] = useRecoilState(BuildYourOwnState);
  const carBuild = useRecoilValue(CarBuildState);
  const [accountDetails, setAccountDetails] =
    useRecoilState(AccountDetailsState);
  const { search, pathname } = useLocation();
  const returnUrl = encodeURI(
    `/${byoState.baseUrlSegment}${pathname}${search}`
  );
  const loginUrl = `/${byoState.baseUrlSegment}/account/login?returnUrl=${returnUrl}`;

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [dealers, setDealers] = useState<Dealer[]>([]);
  const [dealerOptions, setDealerOptions] = useState<Option[] | undefined>(
    undefined
  );
  const {
    control,
    handleSubmit,
    formState,
    reset,
    watch,
    clearErrors,
    trigger,
  } = useForm<FormSchema>({
    resolver: zodResolver(AccountDetailsSchema),
    defaultValues: defaultAccountDetails,
    mode: 'all',
  });

  const isCompany = watch('account.isCompany');

  const onLoad = useCallback(async () => {
    if (carBuild) {
      setIsLoading(true);
      const _accountDetails = await getAccountDetails();
      if (!byoState.isAuthenticated) {
        _accountDetails.address.stateCode = carBuild?.view.suburb.state || '';
        _accountDetails.address.postalCode =
          carBuild?.view.suburb.postcode || '';
      }

      if (!byoState.seller) {
        const seller = await getSellerDetails();
        setByoState((prevState) => ({ ...prevState, seller }));
      }

      setAccountDetails({
        ..._accountDetails,
        account: {
          ..._accountDetails.account,
          dateOfBirth: _accountDetails.account.dateOfBirth
            ? new Date(_accountDetails.account.dateOfBirth)
            : null,
        },
      });

      const stiOnly = carBuild.order.model?.name.toLowerCase().includes('sti');
      const postcode = carBuild.view.suburb.postcode;
      await fetch(
        `/api/dealers?top=15&DepartmentName=Vehicle Sales&ActiveOnly=true&PostCode=${postcode}&stiOnly=${stiOnly}`
      )
        .then((res) => res.json())
        .then((res) => {
          if (Array.isArray(res) && res.length > 0) {
            setDealers(res.map((d: Dealer) => d)); // keep this to map the state code before posting
          }
        })
        .catch((error) => {
          console.error('An error occurred loading dealers.', error);
        });
    }
  }, [carBuild]);

  useEffect(() => {
    setDealerOptions(
      dealers.map((d: Dealer) => {
        const distanceKm = Math.round((d.distance ?? 0) * 10) / 10;
        const location =
          d.dealerDetails.length > 0 ? d.dealerDetails[0]?.suburb : '';
        return {
          label: `${d.name} - ${location} - ${distanceKm} km`,
          value: d.crmDealerId,
        } as Option;
      })
    );
    refresh(true);
  }, [dealers]);

  useEffect(() => {
    onLoad();
  }, [onLoad]);

  const refresh = (scroll = false) => {
    if (accountDetails) {
      reset(accountDetails);
      if (scroll) setTimeout(() => window.scrollTo(0, 0));
      setIsLoading(false);
    }
  };

  useEffect(() => {
    refresh(true);
  }, [accountDetails]);

  const findDealerOption = (value: string | null): Option | undefined =>
    dealerOptions?.find((option) => option.value === value) || undefined;

  const handleFormSubmit = async (data: FormSchema) => {
    try {
      setErrorMessage('');
      setIsSubmitted(true);

      data.address.stateCode = dealers.filter(
        (x) => x.crmDealerId === data.dealerCode
      )[0].state;

      const formData = createFormData(data);
      const result = await postAccountDetails(byoState.id, formData);
      if (result.success) {
        if (result.orderNo && isOrderNo(result.orderNo)) {
          window.location.href = `/${byoState.baseUrlSegment}/order/${result.orderNo}/pay`;
        }
      } else {
        setErrorMessage(result.message || 'Something went wrong');
        setIsSubmitted(false);
      }
    } catch {
      console.error('Something went wrong on account creation');
      setErrorMessage('Something went wrong');
      setIsSubmitted(false);
    }
  };

  return (
    <div className={styles.OrderAccountForm}>
      {isLoading ? (
        <Loading height="100%" />
      ) : (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          {isSubmitted && <OverlayLoader />}
          {!byoState.isAuthenticated && (
            <h5 className={cn(styles.AuthSection)}>
              Already Have An Account?&nbsp;
              <a href={loginUrl}>Login now</a>
            </h5>
          )}
          <h3>Enter your details</h3>
          <section>
            <p>Which one are you?</p>
            <Controller
              name="account.isCompany"
              control={control}
              render={({ field }) => (
                <RadioButton
                  name="isCompany"
                  options={[
                    { label: 'A person', value: 'false' },
                    { label: 'A Company', value: 'true' },
                  ]}
                  selectedValue={field.value ? 'true' : 'false'}
                  onChange={(option) => {
                    field.onChange(option === 'true');
                  }}
                  inline
                />
              )}
            />
          </section>
          {!isCompany ? (
            <>
              <section>
                <p>
                  To help us verify your identity we will need you to upload a
                  photo of your driver&apos;s license. The name and address on
                  the license will need to correlate with your entered customer
                  details. NB we will store, use and disclose any personal
                  information strictly in accordance with our&nbsp;
                  <a href="/privacy-policy" target="_blank">
                    Privacy Policy
                  </a>
                  .
                </p>
              </section>
              <section>
                <label>Upload your licence image</label>
                <Controller
                  name="account.licence"
                  control={control}
                  render={({ field }) => (
                    <FileUpload
                      onFileSelect={(f) => field.onChange(f)}
                      accept="image/*,.pdf"
                      error={formState.errors.account?.licence?.message}
                    />
                  )}
                />
              </section>
            </>
          ) : (
            <>
              <section className="grid grid-cols-12 gap-4">
                <Controller
                  name="account.companyName"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className={`col-span-${byoState.isMobile ? 12 : 8}`}
                      label="Company name"
                      variant="outlined"
                      value={field.value || ''}
                      error={formState.errors.account?.companyName?.message}
                    />
                  )}
                />
                <Controller
                  name="account.companyAbn"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className={`col-span-${byoState.isMobile ? 12 : 4}`}
                      label="ABN/ACN"
                      variant="outlined"
                      value={field.value || ''}
                      error={formState.errors.account?.companyAbn?.message}
                    />
                  )}
                />
                <Controller
                  name="account.jobTitle"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className="col-span-12"
                      label="Job title"
                      variant="outlined"
                      value={field.value || ''}
                    />
                  )}
                />
              </section>
            </>
          )}
          <section className="grid grid-cols-12 gap-4">
            <Controller
              name="account.prefix"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  className={`col-span-${byoState.isMobile ? 12 : 2}`}
                  label="Prefix"
                  variant="outlined"
                  value={field.value || ''}
                />
              )}
            />
            <Controller
              name="account.firstName"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  className={`col-span-${byoState.isMobile ? 12 : 5}`}
                  label="First name"
                  variant="outlined"
                  value={field.value || ''}
                  error={formState.errors.account?.firstName?.message}
                />
              )}
            />
            <Controller
              name="account.lastName"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  className={`col-span-${byoState.isMobile ? 12 : 5}`}
                  label="Last name"
                  variant="outlined"
                  value={field.value || ''}
                  error={formState.errors.account?.lastName?.message}
                />
              )}
            />
            <Controller
              name="account.phoneNo"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  active
                  className={`col-span-${byoState.isMobile ? 12 : 6}`}
                  label="Phone number"
                  placeholder="(0x) xxxx xxxx"
                  variant="outlined"
                  value={field.value || ''}
                  error={formState.errors.account?.phoneNo?.message}
                />
              )}
            />
            <Controller
              name="account.dateOfBirth"
              control={control}
              render={({ field }) => (
                <DatePickerWrapper
                  fixedHeight
                  showYearDropdown
                  scrollableYearDropdown
                  peekNextMonth
                  showMonthDropdown
                  showPopperArrow={false}
                  dateFormatCalendar="MMMM"
                  dropdownMode="select"
                  dateFormat="dd/MM/yyyy"
                  selected={field.value}
                  placeholderText="dd/mm/yyyy"
                  // maxDate={subtractYears(new Date(), 18)}
                  maxDate={new Date()}
                  onChange={(date: Date | null) => {
                    field.onChange(date);
                    clearErrors('account.dateOfBirth');
                  }}
                  className={`col-span-${byoState.isMobile ? 12 : 6}`}
                  customInput={
                    <Input
                      active
                      placeholder="dd/mm/yyyy"
                      label="Date of birth"
                      error={formState.errors.account?.dateOfBirth?.message}
                      variant="outlined"
                      type="text"
                    />
                  }
                />
              )}
            />
          </section>
          <h3>Enter your location</h3>
          <section className="grid grid-cols-12 gap-4">
            <Controller
              name="address.address1"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  className="col-span-12"
                  label="Street Address (Line 1)"
                  variant="outlined"
                  value={field.value || ''}
                  error={formState.errors.address?.address1?.message}
                />
              )}
            />
            <Controller
              name="address.address2"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  className="col-span-12"
                  label="Street Address (Line 2)"
                  variant="outlined"
                  value={field.value || ''}
                />
              )}
            />
            <Controller
              name="address.city"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  className={`col-span-${byoState.isMobile ? 12 : 6}`}
                  label="City"
                  variant="outlined"
                  value={field.value || ''}
                  error={formState.errors.address?.city?.message}
                />
              )}
            />
            <Controller
              name="address.stateCode"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  active
                  disabled
                  className={`col-span-${byoState.isMobile ? 12 : 3}`}
                  label="State"
                  variant="outlined"
                  value={field.value || ''}
                />
              )}
            />
            <Controller
              name="address.postalCode"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  active
                  disabled
                  className={`col-span-${byoState.isMobile ? 12 : 3}`}
                  label="Postcode"
                  variant="outlined"
                  value={field.value || ''}
                />
              )}
            />
            <Controller
              name="address.countryCode"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  active
                  disabled
                  className={`col-span-${byoState.isMobile ? 12 : 3}`}
                  label="Country"
                  variant="outlined"
                  value={'Australia'}
                />
              )}
            />
          </section>
          <h3>
            Which dealer would you like to deliver your new Subaru?&nbsp;
            <span className={styles.Required}>*</span>
          </h3>
          <div className="grid grid-cols-12 gap-4">
            <Controller
              name="dealerCode"
              control={control}
              render={({ field }) => (
                <Dropdown
                  value={findDealerOption(field.value || '')}
                  isFormField
                  onChange={(option) => field.onChange(option?.value || '')}
                  placeholder="Select a dealer"
                  options={dealerOptions || []}
                  isLoading={!dealerOptions}
                  className={cn('col-span-12', styles.DropdownOverride)}
                  error={formState.errors.dealerCode?.message}
                />
              )}
            />
          </div>
          <h3>Like free Subaru Roadside Assistance?</h3>
          <section>
            <p>
              Get help anytime, anywhere. We&apos;ve partnered with
              Australia&apos;s state Motoring Clubs to offer our new Subaru
              customers&nbsp;
              {carBuild?.order?.model?.name === 'Solterra'
                ? '5 years'
                : '12 months'}
              &nbsp;complimentary Roadside Assistance
              <span className="tt">
                *
                <span className="tt__content">
                  Offer excludes Subaru Assured, Certified Used and Fleet and
                  Government customers.
                </span>
              </span>
              &nbsp;and Club Membership. So no matter where you roam, you can
              rest assured that Subaru is there with you, help is not far away.
              This is a complimentary service and can be access by calling&nbsp;
              <strong>13 11 11</strong>
              <span className="tt">
                *
                <span className="tt__content">
                  You may be asked to quote your vehicle registration number and
                  provide an authorisation number, in this case, please use
                  SUB9002.
                </span>
              </span>
              .
            </p>
            <p>Additional Subaru Owner&apos;s Program Benefits Include:</p>
            <ul>
              {carBuild?.order?.model?.name !== 'Solterra' && (
                <li>
                  Subaru&apos;s 5 Year/Limited Kilometre Capped Price Servicing
                  Program
                  <span className="tt">
                    *
                    <span className="tt__content">
                      Every brand new Model Year 2018-onward Subaru comes with
                      Subaru&apos;s 5 Year/Limited Kilometre Capped Price
                      Servicing Program. Available at participating Subaru
                      retailers only and does not apply to rental and some other
                      classes of vehicles. For full Capped Price Servicing Terms
                      and Conditions visit &nbsp;
                      <a href="/service/pricing/capped-price-servicing">
                        subaru.com.au/service/pricing/capped-price-servicing
                      </a>
                      .
                    </span>
                  </span>
                  &nbsp;applicable on all new Subaru vehicles originally
                  purchased from an authorised Subaru Retailer
                </li>
              )}
              <li>Free 1 Month Health Check & Chat</li>
              <li>
                Access to Subaru&apos;s Certified Collision Repairer network
              </li>
              <li>Exclusive invites to Subaru events and special offers</li>
              <li>Genuine Subaru Accessory and Merchandise offers</li>
            </ul>
          </section>
          <section>
            <Controller
              name="accept12MonthsRoadsideAssistance"
              control={control}
              render={({ field }) => (
                <RadioButton
                  name="accept12MonthsRoadsideAssistance"
                  options={[
                    {
                      label: `Yes, I accept the ${
                        carBuild?.order?.model?.name === 'Solterra'
                          ? '5 Year'
                          : '12 Month'
                      } Subaru Roadside Assistance and Membership Offer. I understand that by doing so I am signing up to my state or territory&apos;s Motoring Club and agree to Subaru Australia sharing my personal information with them for the purposes of issuing me with my Membership. Your state club will be in contact with you in 4-6 weeks.`,
                      value: 'true',
                    },
                    {
                      label: `No, I do not wish to accept the ${
                        carBuild?.order?.model?.name === 'Solterra'
                          ? '5 Year'
                          : '12 Month'
                      } Subaru Roadside Assistance and Membership Offer and I am aware that as such, I am forgoing the ${
                        carBuild?.order?.model?.name === 'Solterra'
                          ? '5 Year'
                          : '12 Month'
                      } complementary Subaru Roadside Assistance and Membership Offer.`,
                      value: 'false',
                    },
                  ]}
                  selectedValue={field.value ? 'true' : 'false'}
                  onChange={(option) => {
                    field.onChange(option === 'true');
                  }}
                />
              )}
            />
          </section>
          <p>
            <a
              href={`${byoState.documentBaseUrl}/${
                carBuild?.order?.model?.name === 'Solterra'
                  ? 'Subaru_Solterra_5_Year_Roadside_Assistance_and_Club_Membership_TC%27s.pdf'
                  : '3376-Roadside%20Assist%20Terms%20and%20Conditions%202021_V1.pdf'
              }`}
            >
              Click here
            </a>
            &nbsp;for Subaru Roadside Assistance and Membership Terms and
            Conditions.
          </p>
          <section>
            <h3>How can we contact you?</h3>
            <p>
              We&apos;d like to send you communications from time to time, but
              we completely respect your privacy at&nbsp;
              {byoState.seller?.name || 'Subaru (Aust) Pty Limited'}. You can
              view our Privacy Policy for more details. For now, please let us
              know your preferred way for us to contact you with Subaru-only
              marketing information. Any communication regarding your new Subaru
              will be via phoneNo and email.
            </p>
            <p>Take your pick:</p>
            <div className={styles.CheckBoxGroup}>
              <Controller
                name="account.opInEmailMarketing"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} className={styles.CheckBox}>
                    Email
                  </Checkbox>
                )}
              />
              <Controller
                name="account.opInPostMarketing"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} className={styles.CheckBox}>
                    Post
                  </Checkbox>
                )}
              />
              <Controller
                name="account.opInPhoneAndSmsMarketing"
                control={control}
                render={({ field }) => (
                  <Checkbox {...field} className={styles.CheckBox}>
                    Phone and SMS
                  </Checkbox>
                )}
              />
            </div>
          </section>
          {!byoState.isAuthenticated && (
            <>
              <section>
                <h3>Create your account</h3>
                <p>
                  To continue with buying your Subaru online you&apos;ll need to
                  create an account. This makes your details secure and allows
                  you to track your order, update your details and arrange for
                  someone to talk to you about finance, insurance and more.
                </p>
              </section>
              <section className="grid grid-cols-12 gap-4">
                <Controller
                  name="account.email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className={`col-span-${byoState.isMobile ? 12 : 6}`}
                      label="Email address"
                      variant="outlined"
                      value={field.value || ''}
                      error={formState.errors.account?.email?.message}
                      onBlur={async () => {
                        await trigger('account.emailConfirm');
                        field.onBlur();
                      }}
                    />
                  )}
                />
                <Controller
                  name="account.emailConfirm"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      className={`col-span-${byoState.isMobile ? 12 : 6}`}
                      label="Confirm email address"
                      variant="outlined"
                      value={field.value || ''}
                      error={formState.errors.account?.emailConfirm?.message}
                    />
                  )}
                />
                <Controller
                  name="account.password"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type={showPassword ? 'text' : 'password'}
                      className={`col-span-${byoState.isMobile ? 12 : 6}`}
                      label="Password"
                      variant="outlined"
                      endAdornment={
                        <SvgIcon
                          style={{ cursor: 'pointer' }}
                          color={`#${colorPrimary}`}
                          type={showPassword ? 'eyeClosed' : 'eye'}
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      }
                      value={field.value || ''}
                      error={formState.errors.account?.password?.message}
                      onBlur={async () => {
                        await trigger('account.passwordConfirm');
                        field.onBlur();
                      }}
                    />
                  )}
                />
                <Controller
                  name="account.passwordConfirm"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type={showPasswordConfirm ? 'text' : 'password'}
                      className={`col-span-${byoState.isMobile ? 12 : 6}`}
                      label="Confirm password"
                      variant="outlined"
                      endAdornment={
                        <SvgIcon
                          style={{ cursor: 'pointer' }}
                          color={`#${colorPrimary}`}
                          type={showPasswordConfirm ? 'eyeClosed' : 'eye'}
                          onClick={() =>
                            setShowPasswordConfirm(!showPasswordConfirm)
                          }
                        />
                      }
                      value={field.value || ''}
                      error={formState.errors.account?.passwordConfirm?.message}
                    />
                  )}
                />
              </section>
            </>
          )}
          <section>
            <div className={cn(styles.CheckBoxGroup, styles.TermsAndCondGroup)}>
              <Controller
                name="agreePrivacyCollection"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    {...field}
                    className={styles.TermsAndCond}
                    error={formState.errors.agreePrivacyCollection?.message}
                    onChange={async (c) => {
                      field.onChange(c);
                      await trigger();
                    }}
                  >
                    Please confirm you have read and agreed to our&nbsp;
                    <b>Privacy Collection Statement</b>&nbsp;below by checking
                    this box.
                  </Checkbox>
                )}
              />
            </div>
          </section>
          <section>
            {errorMessage && (
              <div className={styles.ErrorMessage}>{errorMessage}</div>
            )}
            <div className={styles.SubmitGroup}>
              <Button
                nativeType="submit"
                type="primary"
                buttonSize="medium"
                disabled={!formState.isValid || isSubmitted}
              >
                Continue
              </Button>
            </div>
          </section>
          <section>
            <div className={styles.PrivacyTerms}>
              <p>
                <b>Privacy Collection Statement</b>
              </p>
              <p>
                {byoState.seller?.name || 'Subaru (Aust) Pty Limited'}&nbsp;
                &#40;Subaru Australia&#41; collects your personal information in
                order to provide you with relevant updates, newsletters, special
                offers, invitations and materials about the new Subaru BRZ and
                other Subaru related goods and services. Without this
                information may not be able to provide these communications to
                you. We use tools which can track and collect statistics on
                recipient activity to enhance the quality of the marketing
                services we provide to you. In some circumstances we may also
                share your personal information with other organisations that
                assist us in providing products and services to &#40;including
                our authorised dealers&#41;. Subject to the&nbsp;
                <em>Privacy Act</em> 1988 &#40;Cth&#41; you may contact Subaru
                Australia to seek access to the information we hold about you.
                For more information, read our&nbsp;
                <a href="/privacy-policy">Privacy Policy</a>&nbsp;or write to
                the Privacy Officer, Privacy Officer at:&nbsp;
                <a href="mailto:privacy.officer@subaru.com.au">
                  privacy.officer@subaru.com.au
                </a>
                &nbsp; or at Privacy Officer,&nbsp;
                {byoState.seller?.name || 'Subaru (Aust) Pty Limited'}, PO Box
                8311 Norwest NSW 2153.
              </p>
            </div>
          </section>
        </form>
      )}
    </div>
  );
});
