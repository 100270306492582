import React, { Fragment, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './index.module.scss';
import cn from 'classnames';
import { BuildYourOwnState } from '../../store';
import { postLogout } from '~/common/services/build-your-own-service';
import { ProfileNavItem } from '../profile';
import { SvgIcon } from '~/common/components/ui-elements';
import { colorWhite } from '~/common/utils';
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '~/common/components/ui-elements/floating-dialog';
import { useRecoilValue } from 'recoil';

interface IProfileBanner {
  navItems: ProfileNavItem[];
  handleNavClick: (path?: string, orderNumber?: string) => void;
  currentOrderNo?: string;
}

export const ProfileBanner = React.memo((props: IProfileBanner) => {
  const { navItems, handleNavClick, currentOrderNo } = props;
  const { search, pathname } = useLocation();
  const byoState = useRecoilValue(BuildYourOwnState);
  const ref = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const returnUrl = encodeURI(
    `/${byoState.baseUrlSegment}${pathname}${search}`
  );
  const loginUrl = `/${byoState.baseUrlSegment}/account/login?returnUrl=${returnUrl}`;

  const handleLogout = async () => {
    await postLogout().then((res) => {
      if (res.ok) {
        window.location.reload();
      } else {
        console.error('Failed to log out the user.');
      }
    });
  };

  const navClick = (path?: string, orderNumber?: string) => {
    setOpen(false);
    handleNavClick(path, orderNumber);
  };

  return (
    <div className={styles.ProfileBanner}>
      <div className={styles.Container}>
        <div className={styles.Item}>
          {navItems.map((item) => (
            <Fragment key={item.label}>
              {!item.subItems ? (
                <div onClick={() => navClick(item.path)}>
                  <span
                    className={cn(styles.ItemLabel, {
                      [styles.Active]: pathname === item.path,
                    })}
                  >
                    {item.label}
                  </span>
                </div>
              ) : (
                <>
                  {byoState.isMobile ? (
                    <div>
                      <div className={cn({ [styles.SubMenuOpen]: open })}>
                        <span
                          className={cn(styles.ItemLabel, {
                            [styles.Active]: pathname === item.path,
                          })}
                          onClick={() => setOpen((o) => !o)}
                        >
                          {item.label}
                          <SvgIcon
                            type="chevronDown"
                            color={`#${colorWhite}`}
                          />
                        </span>
                      </div>
                      <div
                        className={styles.SubMenu}
                        style={{
                          display: open ? 'block' : 'none',
                        }}
                      >
                        {item.subItems.map((subItem) => (
                          <div
                            className={cn(styles.SubItem)}
                            key={subItem}
                            onClick={() => {
                              navClick(item.path, subItem);
                            }}
                          >
                            {subItem}
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div ref={ref}>
                      <Dialog
                        open={open}
                        onOpenChange={setOpen}
                        root={ref.current}
                      >
                        <DialogTrigger
                          onMouseEnter={() => setOpen(true)}
                          className={cn({
                            [styles.Parent]: !!item.subItems,
                          })}
                        >
                          <span
                            className={cn(styles.ItemLabel, {
                              [styles.Active]: pathname === item.path,
                              [styles.Parent]: !!item.subItems,
                            })}
                            onClick={() => setOpen((o) => !o)}
                          >
                            {item.label}
                            <SvgIcon
                              type="chevronDown"
                              color={`#${colorWhite}`}
                            />
                          </span>
                        </DialogTrigger>
                        <DialogContent
                          contentClassName={cn(
                            styles.DialogOverlayContentOverride
                          )}
                          disableFocusOnDialog
                          hideCloseButton
                        >
                          <div className={styles.SubMenu}>
                            {item.subItems.map((subItem) => (
                              <div
                                className={cn(styles.SubItem, {
                                  [styles.Active]: currentOrderNo === subItem,
                                })}
                                key={subItem}
                                onClick={() => navClick(item.path, subItem)}
                              >
                                {subItem}
                              </div>
                            ))}
                          </div>
                        </DialogContent>
                      </Dialog>
                    </div>
                  )}
                </>
              )}
            </Fragment>
          ))}
        </div>
        {byoState && byoState.isAuthenticated ? (
          <div className={cn(styles.AuthSection)}>
            <p>
              Welcome&nbsp;{byoState.userName},&nbsp;
              <span className={styles.Logout} onClick={handleLogout}>
                Logout
              </span>
            </p>
          </div>
        ) : (
          <div className={cn(styles.AuthSection)}>
            <p>
              Already Have An Account?&nbsp;
              <a href={loginUrl}>Login now</a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
});
